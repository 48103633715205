import React, { Component } from 'react'
import { Modal, Form, Icon, Input, Button, Upload, Select, Row, Col, AutoComplete, Tag } from 'antd'
import { pathOr, path } from 'ramda';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import RichTextEditor from 'react-rte';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import 'firebase/storage';

const { Option } = Select;
const { TextArea } = Input;

class editProfile extends Component {

  constructor(props){
    super(props);
    let str = RichTextEditor.createEmptyValue()
    if(props.company_profile.companydescription){
      // console.log("is in",props.company_profile.companydescription);
      str = RichTextEditor.createValueFromString(props.company_profile.companydescription, 'html');
    }
    this.handleChange = this.handleChange.bind(this)

    this.state = {
      editorValue: str,
      companyDesc: '',
      dataSourceBraSec: [],
      tagsBraSec: [],
      inputValueBraSec: '',
      _geoloc: {
        "lat": 0,
        "lng": 0
      },
      city: ''
    }
  }

  handleChange(value) {
    this.setState({ companyDesc: value })
  }

  handleSubmit = (e) => {
    const { editorValue, _geoloc, city, tagsBraSec } = this.state;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let includeEditor = {...values, description: editorValue.toString('html'), _geoloc: _geoloc, city: city, branches_sectors: tagsBraSec}
        this.props.handleOk(includeEditor)
      }
    });
  }

   onEditorChange = (value) => {
    this.setState({editorValue: value});
  };

  IsInRange0100 = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value >= 0 && value <= 100) {
      callback();
    }else{
      callback('Area code should be 0-100');
    }
  };

  Is15Char = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value.toString().length <= 15) {
      callback();
    }else{
      callback("Number Shouldn't be longer than 15 digits");
    }
  };

  locationAutocomplete = (values) => {
    geocodeByAddress(values.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) =>
      this.setState({
        city: values.description,
        _geoloc: {
          "lat": lat,
          "lng": lng
        }
      })
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.visible !== this.props.visible){
    const { tags_branches_sectors, company_profile } = nextProps;
    let tempBraSec = []
    Object.keys(tags_branches_sectors).map(function (key) {
      return tempBraSec.push(tags_branches_sectors[key].name);
    });
    setTimeout(
      function () {
        this.setState({
          dataSourceBraSec: tempBraSec,
          city: pathOr('', ['company_profile', 'city'], this.props),
          _geoloc: pathOr({}, ['company_profile', '_geoloc'], this.props),
          tagsBraSec: pathOr('', ['branches_sectors'], company_profile)
        });
      }
        .bind(this),
      200
    );
    }
  }

  onSelectBraSec = (value) => {
    this.setState({ inputValueBraSec: value });
    setTimeout(
      function () {
        this.handleInputConfirmBraSec()
      }
        .bind(this),
      200
    );
  }

  handleInputConfirmBraSec = () => {
    const state = this.state;
    const inputValueBraSec = state.inputValueBraSec;
    let tagsBraSec = state.tagsBraSec;
    if (inputValueBraSec && tagsBraSec.indexOf(inputValueBraSec) === -1) {
      tagsBraSec = [...tagsBraSec, inputValueBraSec];
    }

    this.props.form.setFieldsValue({
      'branch-sector': null
    })
    this.setState({
      tagsBraSec,
      inputValueBraSec: '',
    });
  }

  handleCloseBraSec = (removedTag) => {
    const tagsBraSec = this.state.tagsBraSec.filter(tag => tag !== removedTag);
    this.setState({ tagsBraSec });
  }

  render() {
    const { visible, handleCancel, form:{ getFieldDecorator }, submitting, onUploadLogo, onUploadBackground, company_profile, logo, background, t } = this.props;
    const {dataSourceBraSec, tagsBraSec} = this.state;
    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: pathOr('31', ['prefix'], company_profile),
    })(
      <Select style={{ width: 70 }}>
        <Option value="31">+31</Option>
      </Select>
    );

    return (
      <Modal
        title={t("Edit Company Profile")}
        visible={visible}
        onCancel={handleCancel}
        maskClosable={false}
        footer={null}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Form onSubmit={this.handleSubmit} className="login-form">
          <div className="profileLogoParent">
            <div style={{backgroundImage:`url(${background ? background : pathOr('https://firebasestorage.googleapis.com/v0/b/job-forester.appspot.com/o/company%22FhiXXgsW3WmHa2p4bVUrM%2Fviewing-business-charts-and-diagrams-background-working-on-laptop-computer-desk_sg3brgce__F0000.png?alt=media&token=274967b4-8c03-47db-b2d1-3def0536888f', ['company_background'], company_profile)})`}} className="profilebackgroundMainModal" />
            <div style={{backgroundImage:`url(${logo ? logo : pathOr('https://firebasestorage.googleapis.com/v0/b/job-forester.appspot.com/o/company%2FhiXXgsW3WmHa2p4bVUrM%2F3162731295_1e33a2a077.jpg?alt=media&token=81719ddb-ddd2-4d9f-9a1b-c17151163d06', ['company_logo'], company_profile)})`}} className="profileLogoModal" />
            <Form.Item className="profileLogoUploadButtonMain" label="">
              {getFieldDecorator(`company_logo`, {
                rules: [{ required: false, message: t('Please upload your logo!') }],
              })(
                <Upload accept="image/*" customRequest={onUploadLogo} showUploadList={false} >
                  <Button className="profileLogoUploadButton">
                    <Icon type="camera" />{t('UPLOAD PHOTO')}
                  </Button>
                </Upload>
              )}
            </Form.Item>
            <Form.Item className="profileBackgroundUploadButtonMainCompany" label="">
              {getFieldDecorator(`company_background`, {
                rules: [{ required: false, message: t('Please upload your background!') }],
              })(
                <Upload accept="image/*" customRequest={onUploadBackground} showUploadList={false} >
                  <Button className="profileBackgroundUploadButton">
                    <Icon type="camera" /> {t('UPLOAD BANNER')}
                  </Button>
                </Upload>
              )}
            </Form.Item>
          </div>
          <div className="profileForm">
            <Form.Item
              label={t("Company Name")}
            >
              {getFieldDecorator('companyname', {
                rules: [{ required: true, message: t('Please input your company name!') }],
                initialValue: pathOr('', ['companyname'], company_profile),
              })(
                <Input placeholder={t("Company Name")} />
              )}
            </Form.Item>
            <Row>
              <Col className="authInlineLeft" xs={{span:24}} sm={{span:24}} md={{span:7}} lg={{span:7}}>
                <Form.Item
                  label={t("Contact")}
                >
                  {getFieldDecorator('prefix', {
                    rules: [
                      { required: true },
                      {
                        validator: this.IsInRange0100,
                      }
                    ],
                    initialValue: pathOr('', ['contact_prefix'], company_profile),
                  })(
                    <Input placeholder={t("Area Code")} style={{ width: '100%' }} />
                  )}
                </Form.Item>

              </Col>
              <Col className="authInlineRight" xs={{span:24}} sm={{span:24}} md={{span:17}} lg={{span:17}}>
                <Form.Item
                  label={true}
                  colon={false}
                >
                  {getFieldDecorator('phone', {
                    rules: [
                      { required: false },
                      {
                        validator: this.Is15Char,
                      }
                    ],
                    initialValue: pathOr('', ['phone'], company_profile),
                  })(
                    <Input placeholder={t("Company Number")} style={{ width: '100%' }} />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t("sector_branch")}
              className="modalTagDesgin createJobModalLabel ant-form-item-required"
              colon={false}
            >
              {getFieldDecorator('branch-sector', {
                rules: [
                  { required: false, message: t('Please input your company branch/sectior!') },
                  { validator: this.validateBrachSector }
                ],
              })(
                <AutoComplete
                  dataSource={dataSourceBraSec}
                  onSelect={this.onSelectBraSec}
                  filterOption={(inputValueBraSec, option) => option.props.children.toUpperCase().indexOf(inputValueBraSec.toUpperCase()) !== -1}
                  placeholder=""
                >
                  <Input suffix={<Icon type="plus" />} />
                </AutoComplete>
              )}
            </Form.Item>
            {tagsBraSec && tagsBraSec.map((tag, index) => {
              const tagElem = (
                <Tag className="tagDesign" key={tag} closable={true} onClose={() => this.handleCloseBraSec(tag)}>
                  <div className="tabDesigText">{tag}</div>
                </Tag>
              );
              return tagElem;
            })}
            <Form.Item
              label={t('Address')}
            >
              {getFieldDecorator('address', {
                rules: [{ required: true, message: t('Please input your address!') }],
                initialValue: pathOr('', ['address'], company_profile),
              })(
                <Input placeholder={t("Street & Number")} />
              )}
            </Form.Item>
            <Form.Item
              label=""
              style={{ marginBottom: 0 }}
            >
              <Form.Item
                style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
              >
                {getFieldDecorator('postal_code', {
                  rules: [{ required: true, message: t('Please input your postal code!') }],
                  initialValue: pathOr('', ['postal_code'], company_profile),
                })(
                  <Input placeholder={t("Postal Code")} />
                )}
              </Form.Item>
              <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}></span>
              <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <GooglePlacesAutocomplete
                  onSelect={this.locationAutocomplete}
                  initialValue={pathOr('', ['city'], company_profile)}
                  required
                  renderInput={(props) => (
                    <div className="custom-wrapper">
                      <Input {...props} autoComplete="off" onInvalid={(e) => e.target.value ? e.target.setCustomValidity(t("")) : e.target.setCustomValidity(t("Please fill out this field"))} placeholder={t("Plaats")} />
                    </div>
                  )}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label={t("Company Description")}
            >
              <RichTextEditor
                value={this.state.editorValue}
                onChange={this.onEditorChange}
                className="desc-editor"
              />
              {/* <ReactQuill value={this.state.companyDesc}
                  onChange={this.handleChange} /> */}
            </Form.Item>
            <Button className="form-button saveProfileModal" type="primary-global" loading={submitting} htmlType="submit">{t("done")}</Button>
          </div>
        </Form>
      </Modal>
    )
    }
}

// export default ((Form.create())(editProfile))
export default compose(
  firestoreConnect((props, state) => {
    return [
      {
        collection: 'branches_sectors',
      }
    ]
  }),
  connect((state, props) => {
    const { data } = pathOr('', ['firestore'], state)
    return {
      tags_branches_sectors: pathOr('', ['branches_sectors'], data)
    };
  }),
)((Form.create())(editProfile));
