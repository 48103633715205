import React, { Component } from 'react'
import { Row, Col, Icon, Button } from 'antd';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import Moment from 'react-moment';


class Jobsapplied extends Component {

  rejectJob = (id) => {
    const { firestore, authenticated, firebase } = this.props;
    firestore.collection('matched_by_job').doc(authenticated.uid).update({
      liked: firestore.FieldValue.arrayRemove(id)
    });
  }
  render() {
    const {jobYouApplied, t, history} = this.props

    return (
      <Row gutter={10}>
        {(!jobYouApplied || Object.values(jobYouApplied).length === 0) &&
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }} >{t('No jobs applied')}</Col>
        }
          {jobYouApplied && Object.entries(jobYouApplied).map((result, index) => {
            const resultElem = (
              <Col key={index} xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 0 }} >
                <div className="jobAppsearchResult">
                <Link to={'/jobdetails/' + result[0]}>
                  <div className="jobAppsearchHeading">
                    {pathOr("", [1, 'job_title'],result)}
                  </div>
                </Link>
                  <div className="jobAppseniority paddingBottom">{pathOr("", [1, 'seniority'],result)}</div>
                  {/* <div className="jobAppseniority paddingBottom">Tijdelijk/Projectbasis</div> */}
                  <div className="jobAppjobBottomInfoMain">
                    <div style={{backgroundImage:'url('+pathOr('/images/no_image.png', [1, 'companyData', 'company_logo'], result)+')'}} className="jobAppcompanyLogo" />
                    <div className="jobAppcompanyInfoMain">
                      <div className="jobAppcompanyInfo">{pathOr('', [1, 'companyData', 'companyname'], result)}</div>
                      <div className="jobAppcompanyInfo">{pathOr('Address is missing', [1,'companyData', 'address'], result)}</div>
                    </div>
                    <div className="jobAppjobPostedAt">
                      {t('Posted on')} <Moment format="MMMM DD, YYYY" unix>{pathOr("", [1, 'createdAt', 'seconds'],result)}</Moment>
                    </div>
                    <hr />
                    {/* history.push('/message/'+pathOr('', [1, 'company_id'], result)+"/"+result[0]) */}
                    <div>
                      {pathOr(false, [1, 'isMatched'], result) ?
                        <div onClick={() => history.push('/message/'+pathOr('', [1, 'company_id'], result)+"/"+result[0])} className="jobAppsearchAccRejButton borderRight textChangeChat">
                          <Button style={{color: '#DF0E89', border: 'none', lineHeight: '32px', position: 'relative', pointerEvents: 'none'}}>
                            {t('Chat')}
                          </Button>
                        </div>
                        :
                        <div onClick={() => this.rejectJob(pathOr('', [0], result))} className="jobAppsearchAccRejButton borderRight textChangeApplied">
                          <Button style={{color: '#DF0E89', border: 'none', lineHeight: '32px', position: 'relative', pointerEvents: 'none'}}>
                            {t('Applied')}
                            <Icon style={{marginLeft: '10px'}} type="heart" theme="filled"/>
                          </Button>
                        </div>

                      }
                    </div>
                  </div>
                </div>
              </Col>
            );
            return resultElem;
          })}
      </Row>
    )
    }
}

export default compose()((withNamespaces())(Jobsapplied));
