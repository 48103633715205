import React, { Component } from 'react'
import { Button, notification } from 'antd'
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux'
import { compose } from 'redux'

class EmailVerify extends Component {

  componentDidMount = () => {
    this.reloadAuth()
  }

  reloadAuth = () => {
    //Keep checking if the email has been verified from another device
    this.props.firebase.reloadAuth()
    setTimeout(this.reloadAuth, 2000);
  }

  handleOk = () => {
    const { t, firebase } = this.props;
    firebase.auth().languageCode = 'nl';
    firebase.auth().currentUser.sendEmailVerification({
      url:'https://unlocqed.nl/login'
    })
    .then(function () {
      notification['success']({
        message: t('Email has been sent!'),
        description: t('check_spam'),
      });
    })
    .catch((error) => {
      notification['error']({
        message: t('Please wait few minutes!'),
        description: t('waitfor_email'),
      });
    });
  }
  render() {
    const { t } = this.props;
        return (
          <div style={{textAlign:"center"}}>
            <img className="authPaperPlan" src="/images/icon/Paperplane.svg"  align="middle" alt="planLogo"  style={{filter: 'invert(40%) sepia(69%) saturate(1236%) hue-rotate(205deg) brightness(97%) contrast(100%)'}}/>
            <p className="authEmailVerifyText">
              {t('email_verified')}
            <br/><br/>
              {t('check_spam')}
            </p>
            <div style={{textAlign:'center'}}>
              <Button
                className="form-button authtermbutton"
                type="primary-global"
                onClick={this.handleOk}
              >
                {t("resend_email")}
              </Button>
            </div>
          </div>
        )
    }
}

// export default EmailVerify
const enhancer = compose(
  firestoreConnect((props, state) => {
    return []
  }),
  connect((state, props) => {
    return {
      firebase: props && props.firebase,
    }
  }),
)

export default enhancer(EmailVerify)
