import React, { Component } from 'react'
import { Icon } from 'antd'

function Notification ({ notification, onOK, t, valid }) {
  if (!notification) {
    return false
  }
  const { downgraded, renewed, subscribedTo } = notification
  let text;
  let icon;

  if (renewed) {
    if (downgraded) {
      icon = 'check-circle'
      text = `${t('Your subscription has been downgraded to')} ${subscribedTo}. ${t('Next billing is on')} ${valid}`
    } else {
      icon = 'check-circle'
      text = `${t('Your subscription has been renewed.')} ${t('Next billing is on')} ${valid}`
    }
  } else { // Not renewed
    icon = 'warning'
    text = t("We couldn't process your payment, your subscription hasn't been renewed.")
  }

  return (
    <div style={divStyle}>
      <Icon style={iconStyle} type={icon} />
      {text}
      <div style={buttonStyle} onClick={() => onOK()}>OK</div>
    </div>
  )
}

export default Notification;

const divStyle = {
  zIndex: 9999,
  background: 'white',
  padding: '5px 15px',
  borderRadius: 3,
  position: 'relative',
  top: 55,
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  fontSize: 14,
  fontWeight: 'bold'
}

const buttonStyle = {
  height: '21px',
  width: '77px',
  color: '#FFFFFF',
  borderRadius: '5px',
  textAlign: 'center',
  backgroundColor: '#DF0E89',
  fontWeight: 900,
  cursor: 'pointer',
  marginLeft: 'auto'
}

const iconStyle = {
  fontSize: 19,
  color: '#DF0E89',
  marginRight: 15
}
