import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { render } from '../../../utils/form'
import { Form, Input, Button, Alert, Row, Col,  } from 'antd'
import { confirmation } from 'redux-form-validators'
import { connect } from 'react-redux';
const password = value => value && !/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{8,}$/i.test(value) ? 'Password must be a minimum of 8 characters. At least one capital letter and one number.' : undefined
const InputField = render(Input)

class Invite extends Component {

    render() {
        const { signup, error, handleSubmit, submitting, t } = this.props

        return (
            <Form onSubmit={handleSubmit(signup)}>
                {error && <Alert className="error" type="warning" message={error} showIcon />}
                <Field
                    name="password"
                    type="password"
                    validate={password}
                    component={InputField}
                    placeholder={t("Password")}
                />
                <Field
                    name="c_password"
                    type="password"
                    component={InputField}
                    placeholder={t("Confirm Password")}
                    validate={confirmation({ field: 'password', msg: t("Passwords do not match" )})}
                />
                <Button
                  style={{display: 'block'}}
                  className="form-button"
                  type="primary-global"
                  loading={submitting}
                  htmlType="submit"
                >
                  {t("sign_up")}
                </Button>
            </Form>
        )
    }
}

Invite = reduxForm({
  form: 'inviteForm'
})(Invite)

Invite = connect(
  (state, props) => ({
    initialValues: props.active_invite
  })
)(Invite)

export default Invite
