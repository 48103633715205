import './applicationmanagement.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Collapse, Icon, Badge } from 'antd';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { firestoreConnect } from 'react-redux-firebase';
import { withNamespaces } from 'react-i18next';
import Companiesyoufollow from './components/companiesyoufollow';
import Companiesfollowingyou from './components/companiesfollowingyou';
import Jobsapplied from './components/jobsapplied';
import Jobshortlisted from './components/jobsshortlisted';
import Matches from './components/matches';
import {jobManagementNoti, profileManagementNoti} from '../../components/header/header'
export let random_self_job = Math.random().toString(36).substring(7);
let random_self_profile = Math.random().toString(36).substring(7);


const Panel = Collapse.Panel;

export class Applicationmanagement extends Component {
  componentDidMount = () => {
    random_self_job = Math.random().toString(36).substring(7);
    random_self_profile = Math.random().toString(36).substring(7);
  }

  matchedExpand = (key) =>{
    const { firestore, authenticated } = this.props;
    if(key[0] === "1"){
        firestore.collection('notification').doc(authenticated.uid).update({
          matched: firestore.FieldValue.delete()
        });
    }
  }

  profileExpand = (key) =>{
    const { firestore, authenticated } = this.props;
    if(key.includes("2")){
      firestore.collection('notification').doc(authenticated.uid).update({
        follow: firestore.FieldValue.delete()
      });
    }
  }

  jobExpand = (key) =>{
    const { firestore, authenticated } = this.props;
    if(key.includes("2")){
      firestore.collection('notification').doc(authenticated.uid).update({
        shorlisted: firestore.FieldValue.delete()
      });
    }
  }

  render() {
    const {companiesFollowingYou, followingCompanies,jobYouApplied, jobYouShortlisted, matchedCompanies, matchedJobs, authenticated, firestore, firebase, t, history} = this.props;
    return (
      <div className="applicationmanagement">
        <Row gutter={16}>
          <Col lg={{ span: 20, offset: 2 }} >
            <Col lg={{ span: 24, offset: 0 }} >
              <div className="jobManagementPageHeading">{t('Jobs')}</div>
            </Col>
            <Col lg={{ span: 24, offset: 0 }} >
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                onChange={this.jobExpand}
              >
                <Panel header={t("Jobs you applied for")} key="1" className="collapseMain">
                  <Jobsapplied history={history} t={t} firestore={firestore} firebase={firebase} authenticated={authenticated} jobYouApplied={jobYouApplied}/>
                </Panel>
                <Panel header={pathOr(false, ['shorlisted'], jobManagementNoti) ? <Badge dot>{t("Jobs you were shortlisted for")}</Badge> : t("Jobs you were shortlisted for")} key="2" className="collapseMain">
                  <Jobshortlisted t={t} firestore={firestore} authenticated={authenticated} jobYouShortlisted={jobYouShortlisted}/>
                </Panel>
              </Collapse>
            </Col>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={{ span: 20, offset: 2 }} >
            <Col lg={{ span: 24, offset: 0 }} >
              <div className="jobManagementPageHeading">{t('Companies')}</div>
            </Col>
            <Col lg={{ span: 24, offset: 0 }} >
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                onChange={this.profileExpand}
              >
                <Panel header={t("Companies you are following")} key="1" className="collapseMain">
                  <Companiesyoufollow history={history} t={t} firestore={firestore} authenticated={authenticated} firebase={firebase} followingCompanies={followingCompanies} />
                </Panel>
                <Panel header={pathOr(false, ['follow'], jobManagementNoti) ? <Badge dot>{t("Companies Requesting Chat")}</Badge> : t("Companies Requesting Chat")} key="2" className="collapseMain">
                  <Companiesfollowingyou history={history} t={t} firestore={firestore} authenticated={authenticated} companiesFollowingYou={companiesFollowingYou} />
                </Panel>
              </Collapse>
            </Col>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={{ span: 20, offset: 2 }} >
            <Col lg={{ span: 24, offset: 0 }} >
              <div className="jobManagementPageHeading">{t('Matches')}</div>
            </Col>
            <Col lg={{ span: 24, offset: 0 }} >
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
                onChange={this.matchedExpand}
              >
                <Panel header={pathOr(false, ['matched'], jobManagementNoti) ? <Badge dot>{t("Matches")}</Badge> : t("Matches")} key="1" className="collapseMain">
                  <Matches t={t} matchedJobs={matchedJobs} matchedCompanies={matchedCompanies}/>
                </Panel>
              </Collapse>
            </Col>
          </Col>
        </Row>
      </div>
    );
  }
}

export default compose(
  firestoreConnect((props) => {
    const { authenticated } = props;
    return [
      {
        collection: 'matched_by_profile',
        doc: authenticated.uid,
        storeAs: random_self_profile,
      },
      {
        collection: 'matched_by_profile',
        where: [
          ['liked', 'array-contains', authenticated.uid]
        ],
        storeAs: 'matched_by_profile_others'
      },
      {
        collection: 'matched_by_job',
        doc: authenticated.uid,
        storeAs: random_self_job,
      },
      {
        collection: 'matched_by_job',
        where: [
          ['liked', 'array-contains', authenticated.uid]
        ],
        storeAs: 'matched_by_job_others'
      },
      {
        collection: 'company'
      },
      {
        collection: 'job_posting'
      }
    ]
  }),
	connect((state, props) => {
    const { data } =  pathOr('', ['firestore'], state)
    const {uid} = pathOr('', ['authenticated'], props)
    const followingCompanies = {};
    const companiesFollowingYou = {};
    const matchedCompanies = {};
    const jobYouApplied = {};
    const jobYouShortlisted = {};
    const matchedJobs = {};
    Object.entries(pathOr({}, [random_self_profile, 'liked'], data))
    .map(item => {
      if(pathOr([],['matched_by_profile_others', item[1], 'liked'], data).includes(uid)) {
        matchedCompanies[item[1]] = pathOr('', ['company', item[1]], data);
        followingCompanies[item[1]] = pathOr('', ['company', item[1]], data);
        if(followingCompanies[item[1]]){
          followingCompanies[item[1]]['isMatched'] = true;
        }
      }else{
        followingCompanies[item[1]] = pathOr('', ['company', item[1]], data);
      }
      return true
    });
    Object.entries(pathOr({}, ['matched_by_profile_others'], data))
    .map(item => {
      pathOr({}, ['liked'], item[1])
      .map(item_inner => {
        if(item_inner === uid && !pathOr([],[random_self_profile, 'rejectByCandidate'], data).includes(item[0])){
          companiesFollowingYou[item[0]] = pathOr('', ['company', item[0]], data);
        }
        return true;
      });
      return true;
    });


    Object.entries(pathOr({}, [random_self_job, 'liked'], data))
    .map(item => {
      const current_job_data = pathOr('', ['job_posting', item[1]], data)
      if(pathOr([],['matched_by_job_others', item[1], 'liked'], data).includes(uid)){
        jobYouApplied[item[1]] = pathOr('', ['job_posting', item[1]], data);
        if(jobYouApplied[item[1]]){
          jobYouApplied[item[1]]['isMatched'] = true;
        }
        Object.assign(jobYouApplied[item[1]], {companyData: pathOr('', ['company', current_job_data.company_id], data)});
        matchedJobs[item[1]] = pathOr('', ['job_posting', item[1]], data);
        Object.assign(matchedJobs[item[1]], {companyData: pathOr('', ['company', current_job_data.company_id], data)});
      } else {
        jobYouApplied[item[1]] = pathOr('', ['job_posting', item[1]], data);
        Object.assign(jobYouApplied[item[1]], {companyData: pathOr('', ['company', current_job_data.company_id], data)});
      }
      return true
    });

    Object.entries(pathOr({}, ['matched_by_job_others'], data))
    .map(item => {
      Object.entries(pathOr({}, [1, 'liked'], item))
      .map(item_inner => {
        const current_job_data = pathOr('', ['job_posting', item[0]], data)
        if(item_inner[1] === uid){
          jobYouShortlisted[item[0]] = pathOr('', ['job_posting', item[0]], data);
          Object.assign(jobYouShortlisted[item[0]], {companyData: pathOr('', ['company', current_job_data.company_id], data)});
        }
        return true;
      });
      return true;
    });
		return {
      followingCompanies,
      matchedCompanies,
      companiesFollowingYou,
      jobYouApplied,
      jobYouShortlisted,
      matchedJobs
		};
  })
)((withNamespaces())(Applicationmanagement));

