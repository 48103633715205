import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import env from './env/config/environment';

const PRODUCTION = 'production';
const STAGING = 'staging';
const DEMO = 'demo';
const LOCAL = 'local';
const REMOTE = 'remote';

window.ENVIRONMENT = env.ENV_NAME;
window.SERVER = REMOTE;

const firebaseStaging = {
  apiKey: 'AIzaSyCZ7AjLyuFduGgqNkDTaAjDde36kBf6VK4',
  authDomain: 'unlocqed-staging.firebaseapp.com',
  databaseURL: 'https://unlocqed-staging.firebaseio.com',
  projectId: 'unlocqed-staging',
  storageBucket: 'unlocqed-staging.appspot.com',
  messagingSenderId: '658600367502',
  appId: '1:658600367502:web:476dfc99d376b480',
};

const firebaseProduction = {
  apiKey: 'AIzaSyC3F89MCyzpNLvBuqA-gWm2lOnpMngpPMU',
  authDomain: 'job-forester.firebaseapp.com',
  databaseURL: 'https://job-forester.firebaseio.com',
  projectId: 'job-forester',
  storageBucket: 'job-forester.appspot.com',
  messagingSenderId: '411305648919',
};

const firebaseDemo = {
  apiKey: "AIzaSyCYqvb8MKs0wkUk6wCUF1CJqBeHsGY7-3U",
  authDomain: "unlocqed-demo.firebaseapp.com",
  databaseURL: "https://unlocqed-demo.firebaseio.com",
  projectId: "unlocqed-demo",
  storageBucket: "unlocqed-demo.appspot.com",
  messagingSenderId: "251486851100",
  appId: "1:251486851100:web:b67f73c2325c04e6238fd9",
  measurementId: "G-C6MCPXDZ2E"
};

const fbFunctionsURLProduction = 'https://us-central1-job-forester.cloudfunctions.net';
const fbFunctionsURLStaging = 'https://us-central1-unlocqed-staging.cloudfunctions.net';
const fbFunctionsURLDemo = 'https://us-central1-unlocqed-demo.cloudfunctions.net';

const fbFunctionsURLLocalProduction = 'http://localhost:9000/job-forester/us-central1';
const fbFunctionsURLLocalStaging = 'http://localhost:9000/unlocqed-staging/us-central1';
const fbFunctionsURLLocalDemo = 'http://localhost:9000/unlocqed-demo/us-central1';

const fbConfig = window.ENVIRONMENT === PRODUCTION ? firebaseProduction : window.ENVIRONMENT === DEMO ? firebaseDemo : firebaseStaging;
export const fbFunctionsURL = window.SERVER === LOCAL ?
  (window.ENVIRONMENT === PRODUCTION ? fbFunctionsURLLocalProduction : window.ENVIRONMENT === DEMO ? fbFunctionsURLLocalDemo : fbFunctionsURLLocalStaging) :
  (window.ENVIRONMENT === PRODUCTION ? fbFunctionsURLProduction : window.ENVIRONMENT === DEMO ? fbFunctionsURLDemo : fbFunctionsURLStaging);

export const storageUrl = window.ENVIRONMENT === PRODUCTION ? 'gs://job-forester.appspot.com' : window.ENVIRONMENT === DEMO ? 'gs://unlocqed-demo.appspot.com' : 'gs://unlocqed-staging.appspot.com';
export const domainUrl = window.ENVIRONMENT === PRODUCTION ? 'https://unlocqed.nl' : window.ENVIRONMENT === DEMO ? 'https://unlocqed-demo.firebaseapp.com/' : 'https://unlocqed-staging.firebaseapp.com/';

firebase.initializeApp(fbConfig);
const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);

export default firebase;
