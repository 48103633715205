import React, { Component } from 'react'
import { Row, Col, Button } from 'antd';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import Moment from 'react-moment';

const RemoveJobs = []

class Jobsshortlisted extends Component {

  acceptJob = (id) => {
    const { firestore, authenticated } = this.props;
    firestore.collection('users').doc(authenticated.uid).set({ 'disabledJobs': { [id]: true } }, { merge: true });
    firestore.collection('matched_by_job').doc(authenticated.uid).set({
      liked: firestore.FieldValue.arrayUnion(id)
    }, { merge: true});

    firestore.collection('matched_by_job').doc(id).get()
    .then(doc => {
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        if(Object.values(doc.data().liked).includes(authenticated.uid)){
          firestore.collection('notification').doc(id).set({
            matched: firestore.FieldValue.arrayUnion(authenticated.uid)
          }, { merge: true });
        }
      }
    })
    .catch(err => {
      console.log('Error getting document', err);
    });
  }

  rejectJob = (id) => {
    const { firestore, authenticated } = this.props;
    firestore.collection('users').doc(authenticated.uid).set({ 'disabledJobs': { [id]: true } }, { merge: true });
    firestore.collection('matched_by_job').doc(id).update({
      rejectByCandidate: firestore.FieldValue.arrayUnion(authenticated.uid)
    });
    firestore.collection('matched_by_job').doc(id).update({
      liked: firestore.FieldValue.arrayRemove(authenticated.uid)
    });
    RemoveJobs.push(id)
  }

  render() {
    const {jobYouShortlisted, t} = this.props
    return (
      <Row gutter={10}>
        {((!jobYouShortlisted || Object.values(jobYouShortlisted).length === 0) || Object.entries(jobYouShortlisted).filter(item => !RemoveJobs.includes(item[0])).length === 0) &&
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }} >{t('No jobs applied')}</Col>
        }
          {jobYouShortlisted && Object.entries(jobYouShortlisted).filter(item => !RemoveJobs.includes(item[0])).map((result, index) => {
            const resultElem = (
              <Col key={index} xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 0 }} >
                <div className="jobAppsearchResult">
                <Link to={'/jobdetails/' + result[0]}>
                  <div className="jobAppsearchHeading">
                    {pathOr("", [1, 'job_title'],result)}
                  </div>
                </Link>
                  <div className="jobAppseniority paddingBottom">{pathOr("", [1, 'seniority'],result)}</div>
                  {/* <div className="jobAppseniority paddingBottom">Tijdelijk/Projectbasis</div> */}
                  <div className="jobAppjobBottomInfoMain">
                    <div style={{backgroundImage:'url('+pathOr('/images/no_image.png', [1, 'companyData', 'company_logo'], result)+')'}} className="jobAppcompanyLogo" />
                    <div className="jobAppcompanyInfoMain">
                      <div className="jobAppcompanyInfo">{pathOr('', [1, 'companyData', 'companyname'], result)}</div>
                      <div className="jobAppcompanyInfo">{pathOr('Address is missing', [1,'companyData', 'address'], result)}</div>
                    </div>
                    <div className="jobAppjobPostedAt">
                      {t('Posted on')} <Moment format="MMMM DD, YYYY" unix>{pathOr("", [1, 'createdAt', 'seconds'],result)}</Moment>
                    </div>
                    <hr />
                    <div>
                    {pathOr(false, [1, 'isMatched'], result) ?
                      <Link to={'/message/'+pathOr('', [1, 'company_id'], result)+"/"+result[0]}>
                        <div className="jobAppsearchAccRejButton borderRight textChangeChat">
                          <Button style={{color: '#DF0E89', border: 'none', lineHeight: '32px', position: 'relative', pointerEvents: 'none'}}>
                            {t('Chat')}
                          </Button>
                        </div>
                      </Link>
                      :
                      <div style={{paddingBottom:'0px', paddingTop: '0px', height: '31px'}} className="jobAppsearchAccRejButton borderRight">
                        <Col onClick={() => this.acceptJob(pathOr(1, [0], result))} xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} >
                          {t('Apply')}
                          <div />
                        </Col>
                        <Col onClick={() => this.rejectJob(pathOr(1, [0], result))} xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} >
                          {t('Reject')}
                        </Col>
                      </div>
                    }
                    </div>
                  </div>
                </div>
              </Col>
            );
            return resultElem;
          })}
      </Row>
    )
    }
}

export default compose()((withNamespaces())(Jobsshortlisted));
