import React, { Component } from 'react'
import { Row, Col, Icon, Button } from 'antd';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';

class Companiesfollowingyou extends Component {

  state = {
    activeId: ''
  }

  followClick = (id) => {
    const {authenticated, firestore} = this.props
    firestore.collection('matched_by_profile').doc(authenticated.uid).update({
      liked: firestore.FieldValue.arrayUnion(id)
    }).catch( () => {
      firestore.collection('matched_by_profile').doc(authenticated.uid).set({
        liked: firestore.FieldValue.arrayUnion(id)
      })
    })

    firestore.collection('matched_by_profile').doc(id).get()
    .then(doc => {
      if (!doc.exists) {
        firestore.collection('notification').doc(id).set({
          follow: firestore.FieldValue.arrayUnion(authenticated.uid)
        }, { merge: true });
      } else {
        if(Object.values(doc.data().liked).includes(authenticated.uid)){
          firestore.collection('notification').doc(id).set({
            matched: firestore.FieldValue.arrayUnion(authenticated.uid)
          }, { merge: true });
        }else{
          firestore.collection('notification').doc(id).set({
            follow: firestore.FieldValue.arrayUnion(authenticated.uid)
          }, { merge: true });
        }
      }
    })
  }

  unFollowClick = (id) => {
    this.setState({ activeId: id })
    const { firestore, authenticated } = this.props;
    setTimeout(() => {
      firestore.collection('users').doc(authenticated.uid).set({ 'disabledCompanies': { [id]: true } }, { merge: true });
      firestore.collection('matched_by_profile').doc(authenticated.uid).update({
        rejectByCandidate: firestore.FieldValue.arrayUnion(id)
      });
    }, 1500);
  }

  render() {
    const {companiesFollowingYou, t, history} = this.props
    const {activeId} = this.state
    return (
      <Row gutter={10}>
        {(!companiesFollowingYou || Object.values(companiesFollowingYou).length === 0) &&
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} >{t("There are no companies currently following you.")}</Col>
        }
        {companiesFollowingYou && Object.entries(companiesFollowingYou).map((result, index) => {
          const resultElem = (
            <Col className={activeId === pathOr('', [0], result) ? 'hideAnimation' : ''  } key={index} xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 0 }}>
              <div className="searchResult">
                <div style={{backgroundImage:'url('+pathOr('/images/no_image.png', [1, 'company_logo'], result)+')'}} className="companyLogo" />
                <div className="searchHeading">
                  <Link to={'/companyprofile/'+pathOr('', [0], result)}>
                    {pathOr("", [1, 'companyname'],result)}
                  </Link>
                </div>
                <div className="companyInfo">{pathOr("", [1, 'city'],result)}</div>
                <div style={{
                  height: '143px', /*textAlign: 'center', display: 'flex', alignItems: 'center',*/ paddingBottom: '15px'
                }}>
                  {pathOr(false, [1, 'postal_code'], result) &&
                    <div>
                      <div style={{fontSize: '12px', fontWeight: 'bold'}}>{t("address")}</div>
                      <div style={{marginLeft:'10px'}}>{pathOr('', [1, 'address'], result)}</div>
                      <div style={{marginLeft:'10px'}}>{pathOr('', [1, 'postal_code'], result) + ', '+ pathOr('', [1, 'city'], result)}</div>
                    </div>
                  }
                  <br />
                  {pathOr('', [1, 'contact_prefix'], result) &&
                    <div>
                      <div style={{fontSize: '12px', fontWeight: 'bold'}}>{t("contact")}</div>
                      <div style={{marginLeft:'10px'}}>+{pathOr('', [1, 'contact_prefix'], result)}-{pathOr('', [1, 'phone'], result)}</div>
                    </div>
                  }
                </div>
                {pathOr(false, [1, 'isMatched'], result) ?
                  <div onClick={() => history.push('/message/'+result[0])} className="companyFollow borderRight textChangeChat">
                    <Button style={{color: '#DF0E89', border: 'none', lineHeight: '40px', position: 'relative', pointerEvents: 'none'}}>
                      {t('Chat')}
                    </Button>
                  </div>
                :
                  <div className="jobAppsearchAccRejButton">
                    <div className="companyFollow">
                      <Col style={{marginTop: '0px'}} onClick={() => this.followClick(pathOr(1, [0],result))} xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} >
                        {t('Chat')}
                        <div style={{marginTop: 0}} />
                      </Col>
                      <Col style={{marginRight:'0px', width: '50%', marginTop: '0px'}} onClick={() => this.unFollowClick(pathOr(1, [0],result))} xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} >
                        {t('Ignore')}
                      </Col>
                    </div>
                  </div>
                }
              </div>
            </Col>
           );
          return resultElem;
        })}
      </Row>
    )
    }
}

export default compose()((withNamespaces())(Companiesfollowingyou));
