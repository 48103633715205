import './nav.css'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Logo from './components/logo'
import { Layout, Menu, Icon } from 'antd'
import routes from '../../routes'

const { Header } = Layout

export class AdminNav extends Component {

  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  render() {
    const selectedKey = this.props.match.path
    return (
        <Menu
          className="adminNav"
          mode="inline"
          selectedKeys={[selectedKey]}
        >
          <div className="adminTitle">Admin Portal</div>
          {routes.filter(route => route.menu === true && route.admin === true).map(route => (
            <Menu.Item key={route.path}>
              <Link to={route.path}>
                <Icon type={route.icon} />
                <span className="nav-text">{route.label}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
    )
  }
}

export default withRouter(AdminNav)
