import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout, Icon, Spin, Alert } from 'antd';
import { getFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { withNamespaces } from 'react-i18next';
import { firestoreConnect } from 'react-redux-firebase';
import Header from '../header/header';
import Auth from '../../containers/auth/auth';

const { Content, Footer } = Layout;

const DefaultLayout = ({
  component: Component,
  ...rest
}) => {
  const firebase = getFirebase();
  const auth = firebase.auth();
  const authenticated = auth.currentUser;
  const { current_user, company_id, location, t, firestore } = rest;
  const handleClose = () => {
    localStorage.setItem('acceptCookies', true);
    if(authenticated){
      firestore.collection('users').doc(authenticated.uid).set({ 'cookiesAccepted': true }, { merge: true })
    }
  }
  if (current_user && current_user.role === 'admin' && location.pathname.includes('/blogs') && ['/useroverview', '/users', '/statistics', '/companies', '/blogs'].indexOf(location.pathname) !== 0) {
    return (<Redirect from='*' to='/useroverview' />);
  }

  if (location.pathname === "/" || location.pathname.includes('video-call')) {
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout style={{ minHeight: '100vh' }}>
            <Layout style={{ background: '#fff' }}>
              <Content>
                <div style={{ background: '#fff', minHeight: 360 }}>
                  <Component t={t} current_user={current_user} firebase={firebase} authenticated={authenticated} {...matchProps} />
                </div>
                {current_user && !current_user.cookiesAccepted && !localStorage.getItem('acceptCookies') &&
                  <Alert style={{position: 'fixed',bottom: 0,width: '100%'}} afterClose={handleClose} message="Met respect voor uw privacy gebruikt deze website alleen een functionele authenticatie cookie om de website goed te laten werken." closable type="info" />
                }
              </Content>
            </Layout>
          </Layout>
        )}
      />
    );
  }

  if (!rest.private && !authenticated && location.pathname !== "/" && ['/termsandconditions', '/emailverify', '/forgetpassword','/signup','/login'].indexOf(location.pathname) !== 0) {
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout style={{ minHeight: '100vh'}}>
            <Layout style={{ background:'#fff' }}>
              <Header t={t} />
              <Content>
                <div style={{ background: '#fff', minHeight: 360 }}>
                  <Component {...matchProps}/>
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                unlocQed ©2020
              </Footer>
            </Layout>
          </Layout>
        )}
      />
    );
  }

  if (authenticated && authenticated.emailVerified ) {
    if (!current_user) {
      const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
      return (
        <Spin indicator={antIcon} tip="Loading...">
        <div style={{width: '100vw', height: '100vh'}}></div>
        </Spin>
      )
    }
    if ((current_user && current_user.terms_and_conditions)) {

      return (
        <Route
          {...rest}
          render={matchProps => (
            <Layout style={{ minHeight: '100vh'}}>
              <Layout style={{ background:'#fff' }}>
                <Header {...rest} current_user={current_user} company_id={company_id} t={t} />
                <Content>
                  <div style={{ padding: '0px 24px 24px 24px', background: '#fff', minHeight: 360 }}>
                    <Component t={t} current_user={current_user} authenticated={authenticated} company_id={company_id} {...matchProps}/>
                  </div>
                </Content>
                {!current_user.cookiesAccepted && !localStorage.getItem('acceptCookies') &&
                  <Alert style={{position: 'fixed',bottom: 0,width: '100%'}} afterClose={handleClose} message="Met respect voor uw privacy gebruikt deze website alleen een functionele authenticatie cookie om de website goed te laten werken." closable type="info" />
                }
                <Footer style={{ textAlign: 'center' }}>
                  unlocQed ©2020
                </Footer>
              </Layout>
            </Layout>
          )}
        />
      );
    }
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout className="session">
          <Header current_user={current_user} company_id={company_id} t={t} />
          <Auth auth={auth} authenticated={authenticated} current_user={current_user} {...matchProps} />
        </Layout>
      )}
    />
  );
};

const generateUUID = () => { // Public Domain/MIT
  var d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
};

// export default DefaultLayout
export default compose(
  withNamespaces(),
  firestoreConnect( (props, state) => {
    let uid = pathOr(null, ['firebase', 'auth', 'uid'], state.getState());
    if (uid) {
      return [
        {
          collection: 'users',
          doc: uid,
          storeAs: 'currentUser',
        },
        {
          collection: 'education',
        },
        {
          collection: 'skills',
        },
        {
          collection: 'jobs',
        },
      ];
    } else {
      return [];
    }
  }),
  connect( (state, props) => {
    const { education, skills, jobs } = state.firestore.data;
    const current_user = pathOr(null, ['firestore', 'data', 'currentUser'], state);
    let company_id = current_user && current_user.company_id;

    if (!company_id && current_user && current_user.role === 'company') {
      company_id = generateUUID();
    }

    const educationArr = Object.entries(pathOr([], [], education))
      .map(item => {
        return item[1]['name'];
      });

    const skillsArr = Object.entries(pathOr([], [], skills))
      .map(item => {
        return item[1]['name'];
      });

    const jobsArr = Object.entries(pathOr([], [], jobs))
      .map(item => {
        return item[1]['name'];
      });

    return {
      current_user,
      company_id,
      education: educationArr,
      skills: skillsArr,
      jobs: jobsArr,
    };
  }),
)(DefaultLayout);
