import React, { Component } from 'react'
import { Icon } from 'antd'
import { getFirebase } from 'react-redux-firebase';

class AdminHeader extends Component {

  signOut = () => {
    getFirebase().auth().signOut()
    window.location.href = '/'
  }

  render() {
    return (
      <div>
        <div className="headerCover" />
        <div className="adminHeader">
          <div className="adminLogo" style={{float: 'left'}} />
          <Icon onClick={() => this.signOut()} className="adminLogout" type="logout" />
        </div>
      </div>
    )
  }
}

export default AdminHeader;
