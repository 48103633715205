import React, { Component } from 'react'
import { Row, Col, Icon, Button } from 'antd';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';

class Companiesyoufollow extends Component {

  followClick = (id) => {
    const {authenticated, firestore} = this.props
    firestore.collection('matched_by_profile').doc(authenticated.uid).update({
      liked: firestore.FieldValue.arrayRemove(id)
    });
  }
  render() {
    const {followingCompanies, t, history} = this.props
    return (
      <Row gutter={10}>
        {(!followingCompanies || Object.values(followingCompanies).length === 0) &&
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} >{t('You are currently not following any companies')}</Col>
        }
        {followingCompanies && Object.entries(followingCompanies).map((result, index) => {
          const resultElem = (
            <Col key={index} xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 0 }}>
              <div className="searchResult">
                <div style={{backgroundImage:'url('+pathOr('/images/no_image.png', [1, 'company_logo'], result)+')'}} className="companyLogo" />
                <div className="searchHeading">
                  <Link to={'/companyprofile/'+pathOr('', [0], result)}>
                    {pathOr("", [1, 'companyname'],result)}
                  </Link>
                </div>
                <div className="companyInfo">{pathOr("", [1, 'city'],result)}</div>
                <div style={{
                  height: '143px', /*textAlign: 'center', display: 'flex', alignItems: 'center',*/ paddingBottom: '15px'
                }}>
                  {pathOr(false, [1, 'postal_code'], result) &&
                    <div>
                      <div style={{fontSize: '12px', fontWeight: 'bold'}}>{t("address")}</div>
                      <div style={{marginLeft:'10px'}}>{pathOr('', [1, 'address'], result)}</div>
                      <div style={{marginLeft:'10px'}}>{pathOr('', [1, 'postal_code'], result) + ', '+ pathOr('', [1, 'city'], result)}</div>
                    </div>
                  }
                  <br />
                  {pathOr('', [1, 'contact_prefix'], result) &&
                    <div>
                      <div style={{fontSize: '12px', fontWeight: 'bold'}}>{t("contact")}</div>
                      <div style={{marginLeft:'10px'}}>+{pathOr('', [1, 'contact_prefix'], result)}-{pathOr('', [1, 'phone'], result)}</div>
                    </div>
                  }
                </div>
                {pathOr(false, [1, 'isMatched'], result) ?
                <div onClick={() => history.push('/message/'+result[0])} className="companyFollow borderRight textChangeChat">
                  <Button style={{color: '#DF0E89', border: 'none', lineHeight: '40px', position: 'relative', pointerEvents: 'none'}}>
                    {t('Chat')}
                  </Button>
                </div>
                :
                <div onClick={() => this.followClick(pathOr(1, [0],result))} className="companyFollow">
                  <Button style={{color: '#F7D55A', border: 'none', lineHeight: '40px', position: 'relative', pointerEvents: 'none'}}>
                    {t('Following')}
                    <Icon style={{marginLeft:'10px'}} theme="filled" type="star" />
                  </Button>
                </div>
                }
              </div>
            </Col>
           );
          return resultElem;
        })}
      </Row>
    )
    }
}

export default compose()((withNamespaces())(Companiesyoufollow));
