import Loadable from 'react-loadable'
import Loading from './components/common-ui/loading'

// const Dashboard = Loadable({
//   loader: () => import ('./containers/dashboard/dashboard'),
//   loading: Loading
// })

const getComponent = (component) => {
  if(component === 'video-call'){
    return Loadable({
      loader: () => import (`./containers/message/components/Room`),
      loading: Loading
    })
  }else{
    return Loadable({
      loader: () => import (`./containers/${component}/${component}`),
      loading: Loading
    })
  }
};

export default [
  {
    key: 'login',
    label: 'LOGIN',
    icon: 'login',
    exact: true,
    path: '/',
    private: false,
    component: getComponent('home'),
    menu: false,
  },
  {
    key: 'login',
    label: 'LOGIN',
    icon: 'login',
    exact: true,
    path: '/login',
    private: false,
    component: getComponent('auth'),
    menu: false,
  },
  {
    key: 'signup',
    label: 'SIGNUP',
    icon: 'signup',
    exact: true,
    path: '/signup',
    private: false,
    component: getComponent('auth'),
    menu: false,
  },
  // {
  //   key: 'termsandconditions',
  //   label: 'TERMSANDCONDITIONS',
  //   icon: 'termsandconditions',
  //   exact: true,
  //   path: '/termsandconditions',
  //   private: false,
  //   component: getComponent('auth'),
  //   menu: false,
  // },
  {
    key: 'emailverify',
    label: 'EMAILVERIFY',
    icon: 'emailverify',
    exact: true,
    path: '/emailverify',
    private: false,
    component: getComponent('auth'),
    menu: false,
  },
  {
    key: 'forgetpassword',
    label: 'FORGETPASSWORD',
    icon: 'forgetpassword',
    exact: true,
    path: '/forgetpassword',
    private: false,
    component: getComponent('auth'),
    menu: false,
  },
  {
    key: 'resetpassword',
    label: 'RESETPASSWORD',
    icon: 'resetpassword',
    exact: true,
    path: '/action',
    private: false,
    component: getComponent('auth'),
    menu: false,
  },
  {
    key: 'register',
    label: 'register',
    icon: 'user',
    exact: true,
    path: '/register/:inviteid',
    private: false,
    component: getComponent('auth'),
    admin: false,
    menu: false,
  },
  // {
  //   key: 'dashboard',
  //   label: 'DASHBOARD',
  //   icon: 'dashboard',
  //   exact: true,
  //   path: '/dashboard',
  //   private: true,
  //   component: getComponent('dashboard'),
  //   menu: true,
  // },
  {
    key: 'companyprofile',
    label: 'COMPANY PROFILE',
    icon: 'dashboard',
    exact: true,
    path: '/companyprofile/:companyid',
    private: false,
    component: getComponent('companyprofile'),
    menu: true,
  },
  {
    key: 'jobdetails',
    label: 'JOB DETAILS',
    icon: 'dashboard',
    exact: true,
    path: '/jobdetails/:jobid',
    private: false,
    component: getComponent('jobdetails'),
    menu: true,
  },
  {
    key: 'profile',
    label: 'PROFILE',
    icon: 'user',
    exact: true,
    path: '/profile/:profileid?',
    private: false,
    component: getComponent('candidateprofilenew'),
    menu: false,
  },
  {
    key: 'search',
    label: 'SEARCH',
    icon: 'user',
    exact: false,
    path: '/search/:saerchtype?',
    private: false,
    component: getComponent('search'),
    menu: false,
  },
  {
    key: 'job_management',
    label: 'Job Management',
    icon: 'user',
    exact: true,
    path: '/jobmanagement',
    private: true,
    component: getComponent('jobmanagement'),
    menu: false,
  },
  {
    key: 'trackmyjobs',
    label: 'trackmyjobs',
    icon: 'user',
    exact: true,
    path: '/trackmyjobs',
    private: true,
    component: getComponent('applicationmanagement'),
    menu: false,
  },
  {
    key: 'message',
    label: 'MESSAGE',
    icon: 'user',
    exact: true,
    path: '/message/:chatwith?/:jobid?',
    private: true,
    component: getComponent('message'),
    menu: false,
  },
  {
    key: 'trainingcenter',
    label: 'TRAINING CENTER',
    icon: 'user',
    exact: true,
    path: '/dashboard',
    private: true,
    component: getComponent('trainingcenter'),
    menu: false,
  },
  {
    key: 'post',
    label: 'POST',
    icon: 'user',
    exact: true,
    path: '/post/:id',
    private: false,
    component: getComponent('post'),
    menu: false,
  },
  {
    key: 'article1',
    label: 'ARTICLE!',
    icon: 'user',
    exact: true,
    path: '/jij-vind-dè-baan-en-de-wereld-krijgt-meer-zuurstof',
    private: false,
    component: getComponent('article1'),
    menu: false,
  },
  {
    key: 'quotum',
    label: 'Quotum!',
    icon: 'user',
    exact: true,
    path: '/quotum-yay-nay',
    private: false,
    component: getComponent('article4'),
    menu: false,
  },
  {
    key: 'persbericht',
    label: 'Persbericht!',
    icon: 'user',
    exact: true,
    path: '/persbericht',
    private: false,
    component: getComponent('article2'),
    menu: false,
  },
  {
    key: 'privacy-design',
    label: 'privacy!',
    icon: 'user',
    exact: true,
    path: '/privacy-by-design',
    private: false,
    component: getComponent('article3'),
    menu: false,
  },
  {
    key: 'door-matching',
    label: 'door!',
    icon: 'user',
    exact: true,
    path: '/door-objectieve-matching-meer-toewijding-en-werkplezier',
    private: false,
    component: getComponent('article5'),
    menu: false,
  },
  {
    key: 'ben-jij',
    label: 'ben!',
    icon: 'user',
    exact: true,
    path: '/ben-jij-óók-op-zoek-naar-die-leukere-baan',
    private: false,
    component: getComponent('article6'),
    menu: false,
  },
  {
    key: 'useroverview',
    label: 'USER OVERVIEW',
    icon: 'user',
    exact: true,
    path: '/useroverview',
    private: false,
    component: getComponent('useroverview'),
    admin: true,
    menu: true,
  },
  {
    key: 'users',
    label: 'USER STATISTICS',
    icon: 'line-chart',
    exact: true,
    path: '/users',
    private: false,
    component: getComponent('users'),
    admin: true,
    menu: true,
  },
  {
    key: 'companies',
    label: 'SUBSCRIPTIONS',
    icon: 'star',
    exact: true,
    path: '/companies',
    private: false,
    component: getComponent('companies'),
    admin: true,
    menu: true,
  },
  {
    key: 'statistics',
    label: 'JOB STATISTICS',
    icon: 'line-chart',
    exact: true,
    path: '/statistics',
    private: false,
    component: getComponent('statistics'),
    admin: true,
    menu: true,
  },
  {
    key: 'cms',
    label: 'BLOGS',
    icon: 'book',
    exact: true,
    path: '/blogs',
    private: false,
    component: getComponent('blogs'),
    admin: true,
    menu: true,
  },
  {
    key: 'cms',
    label: 'BLOGS EDIT',
    icon: 'book',
    exact: true,
    path: '/blogs/edit/:blogId?',
    private: false,
    component: getComponent('blogsedit'),
    admin: true,
    menu: false,
  },
  {
    key: 'subscribe',
    label: 'subscribe',
    icon: 'user',
    exact: true,
    path: '/subscribe',
    private: false,
    component: getComponent('subscribe'),
    admin: false,
    menu: false,
  },
  {
    key: 'membership',
    label: 'membership',
    icon: 'user',
    exact: true,
    path: '/membership',
    private: false,
    component: getComponent('membership'),
    admin: false,
    menu: false,
  },
  {
    key: 'transaction_history',
    label: 'transaction_history',
    icon: 'user',
    exact: true,
    path: '/transaction_history',
    private: false,
    component: getComponent('transactionHistory'),
    admin: false,
    menu: false,
  },
  {
    key: 'payment-confirmation',
    label: 'payment-confirmation',
    icon: 'user',
    exact: true,
    path: '/payment-confirmation/:companyId',
    private: false,
    component: getComponent('paymentConfirmation'),
    admin: false,
    menu: false,
  },
  {
    key: 'team-members',
    label: 'team-members',
    icon: 'user',
    exact: true,
    path: '/team-members',
    private: false,
    component: getComponent('teammembers'),
    admin: false,
    menu: false,
  },
  {
    key: 'video-call',
    label: 'video-call',
    icon: 'user',
    exact: true,
    path: '/video-call/:messageId/:userId',
    private: true,
    component: getComponent('video-call'),
    admin: false,
    menu: false,
  },
]
