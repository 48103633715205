import './auth.css'
import React, { Component } from "react"
import { connect } from 'react-redux';
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase';
import { login, signup, forgetpassword, resetpassword, signout } from './actions'
import LoginForm from './components/login'
import SignUpForm from './components/signup'
// import TermsAndConditions from './components/termsandconditions'
import EmailVerify from './components/emailverify'
import EmailVerifyText from './components/emailverifytext'
import ForgetPassword from './components/forgetpassword'
import ResetPassword from './components/resetpassword'
import Invite from './components/invite'
import Spinner from '../../components/common-ui/spinner'
// import Landedverify from './components/landedverify'
import { Row, Col } from 'antd'
import { pathOr } from 'ramda'
import { Link } from 'react-router-dom';
import qs from 'query-string';
import { withNamespaces } from 'react-i18next';
import { getFirebase } from 'react-redux-firebase';

export class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabKey: "login"
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  handleTabChange(activeTabKey) {
    this.setState({ activeTabKey });
  }

  componentDidMount() {
    const {authenticated, current_path, current_user } = this.props;

    if (current_user && authenticated && authenticated.email === current_user.email && ['/', '/emailverify', '/forgetpassword', '/signup', '/login'].indexOf(current_path) >= 0) {
      if (current_user.role === 'candidate') {
        window.location.href = '/dashboard';
      } else if (current_user.role === 'admin') {
        window.location.href = '/useroverview';
      } else {
        window.location.href = '/companyprofile/' + current_user.company_id;
      }
    }
  }

  render() {
    const { login, signup, resetpassword, current_path, history, forgetpassword, authenticated, current_user, t, firebase, authFire, signout, active_invite } = this.props;
    const parseSearchPath = qs.parse(history.location.search, { ignoreQueryPrefix: true });

    if((authenticated && !current_user && (current_path === '/dashboard' || current_path.includes('companyprofile')) || (current_path.includes('/register/') && !active_invite))) {
      return (
        <div className="auth auth-override-height-mobile" style={{ minHeight: '100vh'}}>
          <Spinner size="big" />
        </div>
      )
    }

    if (current_path.includes('/register/') && active_invite) {
      return (
        <div className="auth auth-override-height-mobile" style={{ minHeight: '100vh' }}>
          <Col className="mainAuthBox" xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 18, offset: 3 }} lg={{ span: 14, offset: 5 }} xl={{ span: 12, offset: 6 }}>
            <div className="authLinkMain">
              <h1>{t('welcome')}</h1>
              <h2>{t('enter_password')}</h2>
            </div>
            <Invite signup={signup} t={t} active_invite={active_invite} />
          </Col>
        </div>
      );
    }

    return (
      <div className="auth auth-override-height-mobile" style={{ minHeight: '100vh'}}>
        <Row style={{ height: 'calc(100vh - 53px)' }}>
          <Col span={24} style={{ height: 'calc(100vh - 53px)' }}>
            <Row style={{ height: 'calc(100vh - 53px)' }}>
              {authenticated && !authenticated.emailVerified && current_path === '/signup' &&
                <Col className="mainAuthBox" xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }} lg={{ span: 10, offset: 7 }} xl={{ span: 8, offset: 8 }}>
                  <EmailVerify t={t} history={history} />
                </Col>
              }
              {((current_user && current_user.terms_and_conditions) || !current_user)  && (current_path === '/signup' && !authenticated || current_path !== '/signup') &&
                <Col className="mainAuthBox" xs={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} md={{ span: 12, offset: 6 }} lg={{ span: 10, offset: 7 }} xl={{ span: 8, offset: 8 }}>
                  {current_path === '/forgetpassword' &&
                    <div className="authLinkMain">
                      <h1>{t("forget_password")} </h1>
                    </div>
                  }
                  {parseSearchPath.mode === 'resetPassword' && current_path === '/action' &&
                    <div className="authLinkMain">
                      <h1>{t("reset_password")}</h1>
                    </div>
                  }
                  {(!authenticated && ['/emailverify', '/forgetpassword', '/action','/signup'].indexOf(current_path) === -1) &&
                    <div>
                      <div className="authLinkMain">
                        <Link to="/login" className={"authLink authLinkUnderline"}>
                          {t("sign_in")}
                        </Link>
                        <p style={{ display: 'inline-block', margin: '0 10px', color: '#909090' }}>of</p>
                        <Link to="/signup" className={"authLink"}>
                          {t("sign_up")}
                        </Link>
                      </div>
                      <LoginForm t={t} login={login} />
                    </div>
                  }
                  {current_path === '/signup' &&
                    <div>
                      <div className="authLinkMain">
                        <Link to="/login" className={"authLink "}>
                          {t("sign_in")}
                        </Link>
                        <p style={{ display: 'inline-block', margin: '0 10px', color: '#909090' }}>of</p>
                        <Link to="/signup" className={"authLink authLinkUnderline"}>
                          {t("sign_up")}
                        </Link>
                      </div>
                      <SignUpForm history={history} t={t} signup={signup} />
                    </div>
                  }
                  {(authenticated && !authenticated.emailVerified && current_path !== '/action') || current_path === '/emailverify' ?
                    <EmailVerify t={t} history={history} /> : null
                  }
                  {current_path === '/forgetpassword' &&
                    <ForgetPassword t={t} forgetpassword={forgetpassword} history={history} />
                  }
                  {parseSearchPath.mode === 'resetPassword' && current_path === '/action' &&
                    <ResetPassword t={t} resetpassword={resetpassword} history={history} />
                  }
                  {parseSearchPath.mode === 'verifyEmail' && current_path === '/action' &&
                    <EmailVerifyText t={t} history={history} />
                  }
                </Col>
              }
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const actions = {
  login, signup, forgetpassword, resetpassword, signout
};

// export default connect(null, actions)(Auth)
export default compose(
  firestoreConnect( props => {
    const inviteid = pathOr(null,['match', 'params', 'inviteid'] ,props)
    if (inviteid) {
      return [{
        collection: 'invites',
        doc: inviteid,
        storeAs: 'active_invite'
      }]
    } else {
      return []
    }
  }),
  connect((state, props) => {
    const { path } = props.match;
    const { auth } = props;
    const inviteid = pathOr(null, ['match', 'params', 'inviteid'], props);
    let active_invite = pathOr(null, ['firestore', 'data', 'active_invite'], state);

    if (active_invite && inviteid) {
      active_invite.inviteid = inviteid;
    }
    return {
      current_path: path,
      authFire: auth,
      active_invite,
    };
  }, actions),
)(withNamespaces()(Auth));
