import React, { Component } from 'react'
import { Row, Col, Select, Button } from 'antd';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import Moment from 'react-moment';

const Option = Select.Option;

class Matches extends Component {

  state = {
    matchedData: {},
    type: 'jobs'
  }

  componentDidMount = () =>{
    const {matchedJobs} = this.props;
    this.setState({
      matchedData: matchedJobs
    })
  }

  handleChange = (value)  => {
    const {matchedJobs, matchedCompanies} = this.props;
    if(value === 'jobs'){
      this.setState({
        matchedData: matchedJobs,
        type: 'jobs'
      })
    }
    if(value === 'follows'){
      this.setState({
        matchedData: matchedCompanies,
        type: 'follows'
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.matchedJobs || nextProps.matchedCompanies) {
     if(this.state.type === "jobs"){
        this.setState({
          matchedData: nextProps.matchedJobs
        })
      }
      if(this.state.type === "follows"){
        this.setState({
          matchedData: nextProps.matchedCompanies
        })
      }
    }
   }

  render() {
    const {matchedData, type} = this.state;
    const { t } = this.props
    return (
      <Row gutter={10}>
      <Col className="filters" xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
        <Select
          defaultValue="jobs" style={{ width: 120 }}
          onChange={this.handleChange}
        >
          <Option value="jobs">{t('By Jobs')}</Option>
          <Option value="follows">{t('By Follows')}</Option>
        </Select>
      </Col>
      {type === 'jobs' &&
        <div>
          {(!matchedData || Object.values(matchedData).length === 0) &&
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }} >{t('No jobs matched')}</Col>
          }
            {matchedData && Object.entries(matchedData).map((result, index) => {
              const resultElem = (
                <Col key={index} xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 0 }} >
                  <div className="jobAppsearchResult">
                  <Link to={'/jobdetails/' + result[0]}>
                    <div className="jobAppsearchHeading">
                      {pathOr("", [1, 'job_title'],result)}
                    </div>
                  </Link>
                    <div className="jobAppseniority paddingBottom">{pathOr("", [1, 'seniority'],result)}</div>
                    <div className="jobAppjobBottomInfoMain">
                      <div style={{backgroundImage:'url('+pathOr('/images/no_image.png', [1, 'companyData', 'company_logo'], result)+')'}} className="jobAppcompanyLogo" />
                      <div className="jobAppcompanyInfoMain">
                        <div className="jobAppcompanyInfo">{pathOr('', [1, 'companyData', 'companyname'], result)}</div>
                        <div className="jobAppcompanyInfo">{pathOr('Address is missing', [1,'companyData', 'address'], result)}</div>
                      </div>
                      <div className="jobAppjobPostedAt">
                        {t('Posted on')} <Moment format="MMMM DD, YYYY" unix>{pathOr("", [1, 'createdAt', 'seconds'],result)}</Moment>
                      </div>
                      <hr />
                      <div>
                        <Link to={'/message/'+pathOr('', [1, 'company_id'], result)+"/"+result[0]}>
                          <div className="jobAppsearchAccRejButton borderRight textChangeChat">
                            <Button style={{color: '#DF0E89', border: 'none', lineHeight: '32px', position: 'relative', pointerEvents: 'none'}}>
                              {t('Chat')}
                            </Button>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              );
              return resultElem;
            })}
          </div>
        }
        {type === 'follows' &&
          <div>
            {(!matchedData || Object.values(matchedData).length === 0) &&
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }} >{t('No follows matched')}</Col>
            }
            {matchedData && Object.entries(matchedData).map((result, index) => {
              const resultElem = (
                <Col key={index} xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 0 }} >
                  <div className="searchResult">
                    <div style={{backgroundImage:'url('+pathOr('/images/no_image.png', [1, 'company_logo'], result)+')'}} className="companyLogo" />
                    <div className="searchHeading">
                      <Link to={'/companyprofile/'+pathOr('', [0], result)}>
                        {pathOr("", [1, 'companyname'],result)}
                      </Link>
                    </div>
                    <div className="companyInfo">{pathOr("", [1, 'city'],result)}</div>
                    <Link to={'/message/'+result[0]}>
                      <div className="companyFollow">
                        <div>
                          {t('Chat')}
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              );
              return resultElem;
            })}

          </div>
        }
      </Row>
    )
    }
}

export default compose()((withNamespaces())(Matches));
