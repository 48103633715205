import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification, Icon, Popconfirm, Modal, Button, Card, Avatar, Carousel } from 'antd';
import { firestoreConnect } from 'react-redux-firebase';
import { pathOr, path } from 'ramda';
import { compose } from 'redux';
import EditProfile from './components/editProfile'
import AddJob from './components/addJob'
import Notification from './components/notification'
import { withNamespaces } from 'react-i18next';
import Moment from 'react-moment'
import moment from 'moment'
import { Link } from 'react-router-dom';
import axios from 'axios';
import {fbFunctionsURL} from "../../firebase";
import {random_messages, notification_all} from '../../components/header/header'
import './companyprofile.css';
let jobURL = "/images/icon/Message Smaller.svg";
export let matched_by_job = Math.random();
let matched_by_profile_self = Math.random();
let matched_by_profile_others = Math.random();
let random_messages_profile = Math.random();

export class Companyprofile extends Component {

  state = {
    visible: false,
    visibleJob: false,
    submitting: false,
    logo: false,
    background: false,
    company_profile: '',
    jobPostingCount: 0,
    editId: false,
    editData: false,
    candidateFollowYou: [],
    BrwoserWidth: 0,
    activeId: '',
    followBtnLoading: false,
    unfollowBtnLoading: false
  }

  showModal = () => {
    const { company_profile } = this.props;
    this.setState({
      visible: true,
      company_profile: company_profile,
    });
  }

  showModalJob = () => {
    const {company_profile, t, history } = this.props;
    const { jobs, addOns } = company_profile;
    // console.log("jobs",jobs, addOns);
    if ((!jobs || jobs === 0) && (!addOns || addOns === 0)) {
      Modal.confirm({
        title: t("You don't have quota to post a new job."),
        content: (
          <div style={{ marginTop: '20px' }}>
            <p style={{color: 'black'}}>{t('Please purchase additional credits to post a new job opening.')}</p>
          </div>
        ),
        okText: t('Purchase'),
        okType: 'primary-global',
        cancelText: t('cancel'),
        cancelButtonProps: { type: 'primary-global bordered' },
        onOk() {
          history.push('/subscribe');
        },
      });
    } else {
      this.setState({
        visibleJob: true,
        editId: false,
        editData: false
      });
    }
  }

  checkQuota = (companyid) => {
    return new Promise( (resolve, reject) => {
      this.props.firestore.collection('company').doc(companyid).get()
        .then( companySnap => {
          const company = companySnap.data()
          const { jobs, addOns } = company
          if ( (jobs && jobs > 0) || (addOns && addOns > 0)) {
            resolve({jobs, addOns})
          } else {
            reject()
          }
        })
    })
  }

  checkQuotaFollow = (company_id) => {
    return new Promise( (resolve, reject) => {
      this.props.firestore.collection('company').doc(company_id).get()
        .then( companySnap => {
          const company = companySnap.data()
          const {  likes } = company
          if ( likes && (likes > 0 || likes === 'unlimited')) {
            resolve(likes)
          } else {
            reject()
          }
        })
    })
  }

  handleJobOk = (value, braSec, skills, formReset) => {
    const { firestore, companyid, firebase, t } = this.props;
    const temp_array = []
    this.setState({
      visibleJob: false
    });
    pathOr([], ['education_level'], value).map((item, i) => {
      temp_array.push({
        'education_level': item,
        'education': pathOr([], ['education', i], value)
      })
    })
    if(this.state.editId){
      firestore.collection("job_posting")
      .doc(this.state.editId)
      .set({
        education: temp_array,
        branch_sector: braSec,
        skills: skills,
        job_title: pathOr('', ['job-title'], value),
        contact_email: pathOr('', ['contact-email'], value),
        contact_number: pathOr('', ['contact-number'], value),
        contact_person: pathOr('', ['contact-person'], value),
        employment_type: pathOr('', ['employment_type'], value),
        contact_prefix: pathOr('', ['prefix'], value),
        phone: pathOr('', ['phone'], value),
        work_location: pathOr('', ['work-location'], value),
        job_description: pathOr('', ['job-description'], value),
        seniority: pathOr('', ['seniority'], value),
        salary_experience: pathOr('', ['salary-experience'], value),
        salary_end: parseInt(pathOr('', ['salary-end'], value)),
        salary_start: parseInt(pathOr('0', ['salary-start'], value)),
        company_id: pathOr('', [], companyid),
        _geoloc: pathOr({}, ['_geoloc'], value)
      }, { merge: true })
      .then(() => {
          // this.setState({
          //   visibleJob: false
          // });
          formReset.resetFields()
      })
      .catch(function(error) {
          console.error(t("Error adding document:"), error);
      });
    } else {
      this.checkQuota(companyid) //It's been already checked from props, so this is purely for security purposes
        .then( quotas => {
          const {jobs, addOns } = quotas
          const CurrentDate = new Date();
          firestore.collection("job_posting").add({
            education: temp_array,
            branch_sector: braSec,
            skills: skills,
            job_title: pathOr('', ['job-title'], value),
            contact_email: pathOr('', ['contact-email'], value),
            contact_number: pathOr('', ['contact-number'], value),
            contact_person: pathOr('', ['contact-person'], value),
            employment_type: pathOr('', ['employment_type'], value),
            contact_prefix: pathOr('', ['prefix'], value),
            phone: pathOr('', ['phone'], value),
            work_location: pathOr('', ['work-location'], value),
            job_description: pathOr('', ['job-description'], value),
            seniority: pathOr('', ['seniority'], value),
            salary_experience: pathOr('', ['salary-experience'], value),
            salary_end: pathOr('', ['salary-end'], value),
            salary_start: pathOr('', ['salary-start'], value),
            company_id: pathOr('', [], companyid),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            expiredAt: parseInt(CurrentDate.setMonth(CurrentDate.getMonth() + 24)),
            _geoloc: pathOr({}, ['_geoloc'], value),
            status: 1
          })
          .then((docRef) => {
            // this.setState({
            //   visibleJob: false
            // });
            if (jobs !== 'unlimited') {
              if (jobs > 0) {
                firestore.collection('company').doc(companyid).update({
                  jobs: jobs - 1
                })
              } else {
                firestore.collection('company').doc(companyid).update({
                  addOns: addOns - 1
                })
              }
            }
            console.log(t("Document written with ID: "), docRef.id);
            formReset.resetFields()
          })
          .catch(function(error) {
              console.error(t("Error adding document: "), error);
          });
        })
    }
  }

  handleOk = (value) => {
    this.setState({
      visible: false
    })
    const { firestore, companyid, t } = this.props;
    const { logo, background, company_profile } = this.state;
    value['company_logo'] = logo ? logo : pathOr('', ['company_logo'], company_profile);
    value['company_background'] = background ? background : pathOr('', ['company_background'], company_profile);

    firestore.collection('company').doc(companyid).update({
      'companyname': pathOr('', ['companyname'], value),
      'companydescription': pathOr('', ['description'], value),
      'company_logo': pathOr('', ['company_logo'], value),
      'company_background': pathOr('', ['company_background'], value),
      'address': pathOr('', ['address'], value),
      'postal_code': pathOr('', ['postal_code'], value),
      'city': pathOr('', ['city'], value),
      'contact': pathOr('', ['contact'], value),
      'contact_prefix': pathOr('', ['prefix'], value),
      'phone': pathOr('', ['phone'], value),
      'branches_sectors': pathOr('', ['branches_sectors'], value),
      '_geoloc': pathOr({}, ['_geoloc'], value),
    })
    .then(async () => {
      const { factuursturenId } = company_profile;
      if (factuursturenId){
        let company = pathOr('', ['companyname'], value);
        let phone = pathOr('', ['contact'], value);
        let address = pathOr('', ['address'], value);
        let zipcode = pathOr('', ['postal_code'], value);
        let city = pathOr('', ['city'], value);

        const factuursturenObj = {
          factuursturenId,
          ...(company && {company}),
          ...(phone && {phone}),
          ...(address && {address}),
          ...(zipcode && {zipcode}),
          ...(city && {city}),
        };

        try {
          const factuursturenRes = await axios.post(`${fbFunctionsURL}/editCompanyForFactuursturen`, factuursturenObj);
          // console.log('factuursturenRes: ', factuursturenRes);
        } catch (e) {
          console.log('factuursturen error: ', e);
        }
      }

      this.setState({
        logo: false,
        background: false
      });
    })
    .catch(function(error) {
      console.log(error);
      notification.error({
        message: t('Something went wrong!'),
        description: t('Please contact Bricklinq support team'),
      });
    });
  }

  handleCancel = (e) => {
    // console.log(e);
    this.setState({
      visible: false,
      visibleJob: false
    });
  }

  onUploadLogo = (e) => {
    const this_function = this;
    const { companyid } = this.props;
    var storage = this.props.firebase.storage();
    var pathReference = storage.ref(`/company/${companyid}/${Math.random().toString(36).substring(2, 15)+e.file.name}`);
    pathReference.put(e.file).then(function(snapshot) {
      snapshot.ref.getDownloadURL().then(function(downloadURL) {
        this_function.setState({logo: downloadURL})
        e.onSuccess();
      });
    });
  }

  onUploadBackground = (e) => {
    const this_function = this;
    const { companyid } = this.props;
    var storage = this.props.firebase.storage();
    var pathReference = storage.ref(`/company/${companyid}/${Math.random().toString(36).substring(2, 15)+e.file.name}`);
    pathReference.put(e.file).then(function(snapshot) {
      snapshot.ref.getDownloadURL().then(function(downloadURL) {
        this_function.setState({background: downloadURL})
        e.onSuccess();
      });
    });
  }

  UNSAFE_componentWillReceiveProps = (nextProps) =>{
    if(nextProps.company_profile){
      this.setState({
        company_profile: nextProps.company_profile,
        jobPostingCount: Object.values(nextProps.job_posting).filter(item => item.status !== 0).length,
        candidateFollowYou: nextProps.candidateFollowYou
      })
    }
  }

  toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  deleteJob = (id) => {
    const { firestore } = this.props;
    firestore.collection("job_posting")
    .doc(id)
    .set({
      status: 0
    }, { merge: true })
    .then(() => {
        this.setState({
          visibleJob: false
        });
        firestore.collection('messages')
        .where('job_id', '==', id)
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents.');
            return;
          }
          snapshot.forEach(doc => {
            firestore.collection("messages")
            .doc(doc.id)
            .set({
              status: false,
              reveal: false
            }, { merge: true })
            .then(() => {
            })
            .catch(function(error) {
                console.error("Error adding document: ", error);
            });
          });
        })
        .catch(err => {
          console.log('Error getting documents', err);
        });
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
  }

  editJob = (id, value) =>{
    this.setState({
      visibleJob: true,
      editId: id,
      editData: value
    })
  }

  jobPostingRender = () => {
    const { job_posting, t, firebase, userCompanyID, companyid, jobsList } = this.props;
    let object = []

    Object.keys(job_posting).forEach((key) => {
      var value = job_posting[key];

      if(value['status'] !== 0){
        let total_jobs = 0;
        if(jobsList[key]){
         total_jobs = Object.keys(jobsList[key].interestedCandidate).length + Object.keys(jobsList[key].matchedCandidate).length + Object.keys(jobsList[key].suggestedCandidate).length
        }
        object.push(<Col key={key} xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
          <div className="profileRightBottmJobs" >
            <Row>
              <Col span={18}>
              <Link to={'/jobdetails/' + key}>
                <div className="profileJobTitle">
                  {value['job_title']}
                </div>
              </Link>
              </Col>
              <Col span={6}>
              {value['employment_type'] &&
                <div className="ProfileJobDuration">
                  {this.toTitleCase(value['employment_type'])}
                </div>
              }
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="jobPostedAt">
                  {firebase.auth().currentUser && companyid !== userCompanyID &&
                    <span>{t('Posted on')} <Moment format="MMMM DD, YYYY" unix>{pathOr('', ['createdAt', 'seconds'], value)}</Moment></span>
                  }
                  {firebase.auth().currentUser && companyid === userCompanyID &&
                    <div style={{display: 'inline'}}>
                      <span style={{display:'block'}}>&#9679; {t('You have')} {total_jobs} {t('applicants in total')}</span>
                      {(pathOr('', ['expiredAt'], value) - (new Date().setMonth(new Date().getMonth()))) <= 864000000 ?
                        <span style={{color: 'red'}}> &#9679; {t('Expiring on')} <Moment style={{color: 'red'}} format="MMMM DD, YYYY" unix>{parseInt(pathOr('', ['expiredAt'], value))/1000}</Moment></span>
                        :
                        <span> &#9679; {t('Expiring on')} <Moment format="MMMM DD, YYYY" unix>{parseInt(pathOr('', ['expiredAt'], value))/1000}</Moment></span>
                      }
                      <Icon onClick={() => this.editJob(key, value)} theme="filled" style={{float: 'right', cursor:'pointer', lineHeight:'24px'}} type="edit" />
                      <Popconfirm
                        title={"Are you sure delete this "+value['job_title']+" job?"}
                        onConfirm={() => this.deleteJob(key)}
                        okText="Yes"
                        okType="primary-global small"
                        cancelText="No"
                        cancelButtonProps = {{type: 'primary-global bordered small'}}
                      >
                        <Icon theme="filled" style={{float: 'right', marginRight: '10px', cursor:'pointer', lineHeight:'24px'}} type="delete" />
                      </Popconfirm>
                    </div>
                  }
                </div>
                {!firebase.auth().currentUser &&
                  <div className="profileJobLogin profileEditButton">
                    <Link to={'/jobmanagement'}>{t("login_to_view_jobs")}</Link>
                  </div>
                }
              </Col>
            </Row>
          </div>
        </Col>)
      }
    })

    return object
  }

  jobPostingRenderAdd = () => {
    const {t} = this.props
    return (
      <Col key={1} xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
        <div onClick={this.showModalJob} className="profileRightBottmJobsAdd" >
          <img src="/images/Briefcase_Add_button.svg" />
          <div className="postingjobmainblue">{t("You do not have a job posting yet")}</div>
          <div className="postingjobmaingrey">{t('Post a job now by clicking this card')}</div>
        </div>
      </Col>
    )
  }

  closeNotification = () => {
    let { company_id, firestore, company_profile } = this.props
    company_profile.notification = null
    firestore.collection('company').doc(company_id).update({
      notification: null
    }).then( () => {
      this.setState({company_profile})
    })
  }

  followCandidate = (id) => {
    const {company_id, firestore, history} = this.props
    const _this = this

    _this.checkQuotaFollow( company_id )
      .then( likes => {
        this.setState({ activeId: id })

        setTimeout(() => {
          firestore.collection('matched_by_profile').doc(company_id).update({
            liked: firestore.FieldValue.arrayUnion(id)
          })
          .then(() => {
            setTimeout(() => {
              history.push(`/message/${id}`);
            }, 500)
          })
          .catch( () => {
            firestore.collection('matched_by_profile').doc(company_id).set({
              liked: firestore.FieldValue.arrayUnion(id)
            }).then(() => {
              setTimeout(() => {
                history.push(`/message/${id}`);
              }, 500)
            })
          })
          firestore.collection('matched_by_profile').doc(id).get()
          .then(doc => {
            if (!doc.exists) {
              firestore.collection('notification').doc(id).set({
                follow: firestore.FieldValue.arrayUnion(company_id)
              }, { merge: true });
            } else {
              if(Object.values(doc.data().liked).includes(company_id)){
                firestore.collection('notification').doc(id).set({
                  matched: firestore.FieldValue.arrayUnion(company_id)
                }, { merge: true });
              }else{
                firestore.collection('notification').doc(id).set({
                  follow: firestore.FieldValue.arrayUnion(company_id)
                }, { merge: true });
              }
            }
          })
        }, 1500, id, company_id, firestore);

        if (likes !== 'unlimited') {
          firestore.collection('company').doc(company_id).update({
            likes: likes - 1
          })
        }
    })
    .catch(error => {
      _this.showModalFollow();
    });
  }


  showModalFollow = () => {
    const { t, history } = this.props;
    Modal.confirm({
      title: t("You don't have quota to shortlist/follow a candidate."),
      content: (
        <div style={{ marginTop: '20px' }}>
          <p style={{color: 'black'}}>{t('Please upgrade your subscription to follow/shortlist candidates.')}</p>
        </div>
      ),
      okText: t('Purchase'),
      okType: 'primary-global',
      cancelText: t('cancel'),
      cancelButtonProps: { type: 'primary-global bordered' },
      onOk() {
        history.push('/subscribe');
      },
    });
  }

  ignoreCandidate = (id) => {
    this.setState({ activeId: id })
    const {company_id, firestore} = this.props
    setTimeout(() => {
      firestore.collection('matched_by_profile').doc(company_id).update({
        ignored: firestore.FieldValue.arrayUnion(id)
      }).catch( () => {
        firestore.collection('matched_by_profile').doc(company_id).set({
          ignored: firestore.FieldValue.arrayUnion(id)
        })
      })
    }, 1500);
  }

  componentDidMount = () => {
    matched_by_job = Math.random();
    random_messages_profile = Math.random();
    matched_by_profile_self = Math.random();
    matched_by_profile_others = Math.random();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
      this.setState({BrwoserWidth: window.innerWidth});
  }

  followCompany = () => {
    const {authenticated, firestore, companyid, history} = this.props
    if(!authenticated){
      history.push('/login')
    }else{
      this.setState({
        followBtnLoading: true,
        unfollowBtnLoading: false
      })
      firestore.collection('matched_by_profile').doc(authenticated.uid).update({
        liked: firestore.FieldValue.arrayUnion(companyid)
      }).catch( () => {
        firestore.collection('matched_by_profile').doc(authenticated.uid).set({
          liked: firestore.FieldValue.arrayUnion(companyid)
        })
      })

      firestore.collection('matched_by_profile').doc(companyid).get()
      .then(doc => {
        if (!doc.exists) {
          firestore.collection('notification').doc(companyid).set({
            follow: firestore.FieldValue.arrayUnion(authenticated.uid)
          }, { merge: true });
        } else {
          if(Object.values(doc.data().liked).includes(authenticated.uid)){
            firestore.collection('notification').doc(companyid).set({
              matched: firestore.FieldValue.arrayUnion(authenticated.uid)
            }, { merge: true });
          }else{
            firestore.collection('notification').doc(companyid).set({
              follow: firestore.FieldValue.arrayUnion(authenticated.uid)
            }, { merge: true });
          }
        }
        matched_by_profile_others = Math.random();
      })
    }
  }

  unFollowCompany = () =>{
    this.setState({
      unfollowBtnLoading: true,
      followBtnLoading: false
    })
    const {authenticated, firestore, companyid} = this.props
    matched_by_profile_others = Math.random();
    firestore.collection('matched_by_profile').doc(authenticated.uid).update({
      liked: firestore.FieldValue.arrayRemove(companyid)
    })
    .catch( () => {
      firestore.collection('matched_by_profile').doc(authenticated.uid).set({
        liked: firestore.FieldValue.arrayRemove(companyid)
      })
    })
  }

  render() {
    const { visible, visibleJob, submitting, company_profile, logo, background, jobPostingCount, editData, editId, candidateFollowYou, BrwoserWidth, activeId, followBtnLoading, unfollowBtnLoading } = this.state;
    const { t, userCompanyID, companyid, jobsListNoti, messageCompanyNoti, followersNoti, matchedForCandidate, followForCandidate } = this.props;
    const { notification, subscriptionValid } = company_profile
    const defaultProps = {
      dots: false,
      arrows: true,
      infinite: false,
      nextArrow: <div><Icon type="right" /></div>,
      prevArrow: <div><Icon type="left" /></div>,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

    return (
      <div>
        {company_profile ?
          <div className="companyprofile">
            { notification && companyid === userCompanyID &&
              <Notification
                t={t}
                notification={notification}
                onOK={this.closeNotification}
                valid={moment(subscriptionValid).format('DD. MM. YYYY.')}
              />
            }
            <Row>
              <div style={{backgroundImage:`url(${pathOr('/images/user-circle.png', ['company_background'], company_profile)})`}} className="profilebackgroundMain" />
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 4, offset: 2 }} >
              {companyid === userCompanyID &&
                  <div className="trainingcenter-details-card">
                    <Link to="/jobmanagement">
                      <div><Icon type="user" style={{ fontSize: 15, color: 'black' }} /> <p>{t('Applicants')} ({jobsListNoti})</p></div>
                    </Link>
                    <Link to="/jobmanagement?panel=2">
                      <div><Icon type="team" style={{ fontSize: 15, color: 'black'  }} /> <p>{t('Followers')} ({followersNoti})</p></div>
                    </Link>
                    <Link to="/message">
                      <div> <Icon type="message" style={{ fontSize: 15, color: 'black'  }} /> <p>{t('Messages')} ({messageCompanyNoti})</p></div>
                    </Link>
                    <Link to="/search">
                      <div> <Icon type="search" style={{ fontSize: 15, color: 'black'  }} /> <p>{t('Search Candidates')}</p></div>
                    </Link>
                  </div>
                }
                <div className="profileLeft">
                  <div>
                    {pathOr(false, ['company_logo'], company_profile) ?
                      <div className="profileLogoMain">
                        <div style={{backgroundImage:`url(${pathOr(false, ['company_logo'], company_profile)})`}} className="profileLogo" />
                      </div>
                      :
                      <div className="profileLogoMain">
                        <div style={{backgroundColor:"#909090"}} className="profileLogo" >
                          {companyid === userCompanyID &&
                            <div className="profileLogoUploadButton" onClick={this.showModal} style={{top:'130px', width: '150px', cursor:'pointer'}}>
                              <Icon type="camera" /> {t('UPLOAD PHOTO')}
                            </div>
                          }
                        </div>
                      </div>
                    }
                    <div className="profileTitleMain">
                      <div></div>
                      <div className="profileCompanyTitle">{pathOr('', ['companyname'], company_profile)}</div>
                      <div className="profileTitle">{t("address")}</div>
                      <div className="profileDesc">{pathOr('', ['address'], company_profile)}</div>
                      <div className="profileDesc">{pathOr('', ['postal_code'], company_profile) + ' ' + pathOr('', ['city'], company_profile)}</div>
                      <div className="profileTitle">{t("contact")}</div>
                      <div className="profileDesc">{pathOr('', ['contact_prefix'], company_profile)}-{pathOr('', ['phone'], company_profile)}</div>
                      {companyid === userCompanyID &&
                        <div className="profileTitle profileEditButton" onClick={this.showModal}>{t("edit_company")}</div>
                      }
                    </div>
                  </div>
                  {companyid !== userCompanyID &&
                    <div>
                    {matchedForCandidate ?
                      <div className="acceptDecline">
                        <div className="tc-accept-button">
                          {t("Mathced")}
                        </div>
                      </div>
                    :
                      followForCandidate ?
                        <div className="acceptDecline">
                          <div onClick={this.unFollowCompany} className="tc-accept-button">
                            {t("Unfollow")} {unfollowBtnLoading && <Icon type="loading" /> }
                          </div>
                        </div>
                      :
                        <div className="acceptDecline">
                          <div onClick={this.followCompany} className="tc-accept-button">
                            {t("Follow")} {followBtnLoading && <Icon type="loading" /> }
                          </div>
                        </div>
                    }
                    </div>
                  }
                </div>
              </Col>
              {companyid === userCompanyID ?
                <Col className="profileRight followers" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 0 }}>
                  <div className="profileRightSubHeading">{t("Most Recent Followers")}</div>
                  <Carousel {...defaultProps}>
                    {Object.values(candidateFollowYou).map((item, i) => {
                      return (
                        <Card key={i} className={activeId === pathOr('', ['id'], item) ? 'hideAnimation' : ''  }>
                          <Link to={'/profile/' + pathOr('1', ['id'], item)}>
                            <Row>
                              <Col xs={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }}>
                                <Avatar style={{backgroundColor: '#E0E0E0'}} />
                              </Col>
                              <Col xs={{ span: 20, offset: 0 }} lg={{ span: 20, offset: 0 }}>
                                {item.name.slice(0, 20)}
                              </Col>
                            </Row>
                            <div className="title">{t("Current Job")}</div>
                            <div className="content">{item.currentJob ? item.currentJob : '-'}</div>
                            <div className="title">{t("Seniority")}</div>
                            <div className="content">{item.seniority ? item.seniority : '-'}</div>
                            <div className="title">{t("Desired Job")}</div>
                            <div className="content">{item.desiredJob ? item.desiredJob : '-'}</div>
                          </Link>
                          <Row style={{ marginBottom: 0 }}>
                            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                              <Button onClick={() => this.followCandidate(item.id)} type="secondary-global small">{t("Chat")}</Button>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 11, offset: 1 }}>
                              <Button onClick={() => this.ignoreCandidate(item.id)} type="secondary-global bordered small">{t("Ignore")}</Button>
                            </Col>
                          </Row>
                        </Card>);
                    })}
                  </Carousel>
                  {Object.keys(candidateFollowYou).length !== 0 &&
                    <Col className="followCount" xs={{ span: 24, offset: 0 }} lg={{ span: 24, offset: 0 }}>
                      {BrwoserWidth >= 1025 ? Object.keys(candidateFollowYou).length >= 3 ? 3 : Object.keys(candidateFollowYou).length : BrwoserWidth >= 600 ? Object.keys(candidateFollowYou).length >= 2 ? 2 : Object.keys(candidateFollowYou).length : Object.keys(candidateFollowYou).length >= 1 ? 1 : Object.keys(candidateFollowYou).length}/{Object.keys(candidateFollowYou).length} {t("Followers")}
                    </Col>
                  }
                </Col>
              :
                <Col className="profileRight" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 0 }} >
                  <div className="profileHeadingTitle">{t("about_us")}<hr size="30" className="profileHeadingLine" /></div>
                  <div className="profileDesc" dangerouslySetInnerHTML={{__html: pathOr('', ['companydescription'], company_profile)}} ></div>
                </Col>
              }
              <Col className="profileRightMiddle" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 0 }} >
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                  <div className="profileRightSubHeading">
                    {t('Recently posted jobs')}
                  </div>
                </Col>
                <Col xs={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }}>
                  {jobPostingCount !== 0 && companyid === userCompanyID &&
                    <div className="profileRightSubHeading profileRightSubHeadingRight">
                      <span style={{paddingRight:'10px'}}>{t('Add new job posting')}</span>
                      <Icon onClick={this.showModalJob} className="createJobs" theme="filled" type="plus-circle" />
                    </div>
                  }
                </Col>
              </Col>
              <Col className="profileRightBottm" xs={{ span: 24, offset: 0 }} lg={{ span: 16, offset: 0 }} >
                <Row gutter={16}>
                  {jobPostingCount !== 0 && this.jobPostingRender()}
                  {jobPostingCount === 0 && companyid === userCompanyID && this.jobPostingRenderAdd()}
                  {jobPostingCount === 0 && companyid !== userCompanyID && <div style={{textAlign: 'center'}}>{t("This company doesnt have any job posting")}</div>}
                </Row>
              </Col>
              <AddJob
                showModal = {this.showModal}
                visible = {visibleJob}
                submitting={submitting}
                handleOk = {this.handleJobOk}
                handleCancel = {this.handleCancel}
                firebase={this.props.firebase}
                t={t}
                editData={editData}
                editId={editId}
              />
              <EditProfile
                showModal = {this.showModal}
                visible = {visible}
                submitting={submitting}
                handleOk = {this.handleOk}
                handleCancel = {this.handleCancel}
                onUploadLogo = {this.onUploadLogo}
                onUploadBackground = {this.onUploadBackground}
                firebase={this.props.firebase}
                company_profile={company_profile}
                logo={logo}
                background={background}
                t={t}
              />
            </Row>
          </div>
        :
          <div>
            {t('Loading')}
          </div>
        }
      </div>
    );
  }
}

export default compose(
	firestoreConnect((props, state) => {
    const { companyid } =  pathOr('', ['match', 'params'], props)
    return [
      {
        collection: 'matched_by_job',
        storeAs: matched_by_job
      },
      {
        collection: 'company',
        doc: companyid,
        storeAs: 'company_profile'
      },
      {
        collection: 'matched_by_profile',
        doc: companyid,
        storeAs: matched_by_profile_self,
      },
      {
        collection: 'matched_by_profile',
        where: [
          ['liked', 'array-contains', companyid]
        ],
        storeAs: matched_by_profile_others
      },
      {
        collection: 'users',
        where: [
          ['role', '==', 'candidate']
        ],
        storeAs: 'candidateUsers'
      },
      {
        collection: 'job_posting',
        where: [
          ['company_id', '==', companyid]
        ],
      },
    ]
  }),
	connect((state, props) => {
    const { data } =  pathOr('', ['firestore'], state)
    const { companyid } =  pathOr('', ['match', 'params'], props)
    const { authenticated } =  pathOr('', [], props)
    let jobPosting = pathOr({}, ['job_posting'], data);
    const candidateYouFollowKeys = pathOr('', [matched_by_profile_self, 'liked'], data);
    const candidateYouFollow = [];
    const candidateFollowYou = [];
    const matchedProfile = []
    const jobsList = [];
    const jobsListNoti = [];
    const followersNoti = [];
    const candidateWhoLikesJobs = {};
    const shorlisted_candidate = [];

    let tempJobPosting = Object.keys(jobPosting).filter(key => {
      return jobPosting[key].company_id === companyid
    }).reduce((obj, key) => {
      jobPosting[key]['tested'] = true;
      return {
        ...obj,
        [key]: jobPosting[key]
      };
    }, {});

    Object.entries(pathOr({}, [], jobPosting)).filter(item => {return item[1].status !== 0}).map(item=>{
      const interestedCandidate = {};
      const suggestedCandidate = {};
      const matchedCandidate = {};
      Object.entries(pathOr({}, [matched_by_job], data))
      .map(mbj=>{
          if(pathOr([], [matched_by_job, mbj[0], 'liked'], data).includes(item[0])){
            suggestedCandidate[mbj[0]] = pathOr(false, ['candidateUsers', mbj[0]], data)
            if(suggestedCandidate[mbj[0]]){
              suggestedCandidate[mbj[0]]['suggested'] = true;
            }
            candidateWhoLikesJobs[mbj[0]] = true
          }
          if(pathOr([], [matched_by_job, item[0], 'liked'], data).includes(mbj[0])){
            shorlisted_candidate[mbj[0]] = true;
            interestedCandidate[mbj[0]] = pathOr(false, ['candidateUsers', mbj[0]], data)
            if(interestedCandidate[mbj[0]]){
              interestedCandidate[mbj[0]]['interested'] = true;
            }
          }
        return true;
      })
      Object.entries(pathOr({}, [], interestedCandidate))
      .map(matched=>{
        if(suggestedCandidate[matched[0]]){
          candidateWhoLikesJobs[matched[0]] = true;
          matchedCandidate[matched[0]] = suggestedCandidate[matched[0]]
          matchedCandidate[matched[0]]['matched'] = true;
          delete suggestedCandidate[matched[0]];
          delete interestedCandidate[matched[0]];
        }
        return true;
      })
      jobsList[item[0]] = {
        'jobDetails': item[1],
        interestedCandidate,
        suggestedCandidate,
        matchedCandidate
      }
      return true;
    })

    Object.values(candidateYouFollowKeys).map(item=>{
      candidateYouFollow[item] = pathOr(false, ['candidateUsers', item], data)
      return true;
    })
    Object.entries(pathOr({}, [matched_by_profile_others], data))
    .map(item=>{
      if(pathOr([], [1, 'liked'], item).includes(companyid)){
        candidateFollowYou[item[0]] = {
          name: 'Candidate #'+item[0],
          currentJob: pathOr('', ['candidateUsers', item[0], 'profile', 'current_job'], data),
          seniority: pathOr('', ['candidateUsers', item[0], 'profile', 'seniority'], data),
          desiredJob: pathOr('', ['candidateUsers', item[0], 'profile', 'preferred_job', '0'], data),
          id: item[0],
        };
      }
      return true;
    })
    Object.entries(pathOr({}, [], candidateYouFollow))
    .map(matched=>{
      if(candidateFollowYou[matched[0]]){
        matchedProfile[matched[0]] = candidateYouFollow[matched[0]]
        delete candidateYouFollow[matched[0]];
        delete candidateFollowYou[matched[0]];
      }
      return true;
    })

    Object.entries(pathOr({}, [matched_by_profile_self, 'ignored'], data))
    .map(ignored=>{
      if(candidateFollowYou[ignored[1]]){
        delete candidateFollowYou[ignored[1]];
      }
      return true;
    })

    Object.entries(pathOr({}, [], jobPosting)).filter(item => {return item[1].status !== 0}).map(item=>{
      const interestedCandidate = {};
      const suggestedCandidate = {};
      const matchedCandidate = {};
      Object.entries(pathOr({}, [matched_by_job], data))
      .map(mbj=>{
          if(pathOr([], [matched_by_job, mbj[0], 'notification'], data).includes(item[0])){
            suggestedCandidate[mbj[0]] = pathOr(false, ['candidateUsers', mbj[0]], data)
            if(suggestedCandidate[mbj[0]]){
              suggestedCandidate[mbj[0]]['suggested'] = true;
            }
            candidateWhoLikesJobs[mbj[0]] = true
          }
          if(pathOr([], [matched_by_job, item[0], 'notification'], data).includes(mbj[0])){
            shorlisted_candidate[mbj[0]] = true;
            interestedCandidate[mbj[0]] = pathOr(false, ['candidateUsers', mbj[0]], data)
            interestedCandidate[mbj[0]]['interested'] = true;
          }
        return true;
      })
      Object.entries(pathOr({}, [], interestedCandidate))
      .map(matched=>{
        if(suggestedCandidate[matched[0]]){
          candidateWhoLikesJobs[matched[0]] = true;
          matchedCandidate[matched[0]] = suggestedCandidate[matched[0]]
          matchedCandidate[matched[0]]['matched'] = true;
          delete suggestedCandidate[matched[0]];
          delete interestedCandidate[matched[0]];
        }
        return true;
      })
      // console.log(data[notification_all][item[0]])
      if(pathOr(false, [notification_all, item[0], 'applied'], data) || pathOr(false, [notification_all, item[0], 'matched'], data)){
        jobsListNoti[item[0]] = true
      }
      return true;
    })

    const messages_combined_active = Object.entries(pathOr({}, [random_messages], data))
    .filter(item => {
      return item[1].company_id === companyid
    })
    .filter(item => {
      return item[1].status === true
    })
    .filter(item => {
      return item[1][companyid] === true
    })

    if(pathOr(false, [notification_all, companyid], data)){
      if(Object.keys(pathOr([], [notification_all, companyid], data)).length > 0){
        followersNoti[companyid] = true
      }
    }


		return {
      company_profile: pathOr('', ['company_profile'], data) ? pathOr('', ['company_profile'], data) :  pathOr('', ['company_data'], data) , //pathOr('', ['currentUser', 'role'], data) === 'company' ? pathOr('', ['company_data'], data) : pathOr('', ['company_data', companyid], data),
      job_posting: tempJobPosting,
      companyid: companyid,
      userCompanyID: pathOr(false, ['currentUser', 'company_id'], data),
      jobsList:jobsList,
      candidateFollowYou: candidateFollowYou,
      jobsListNoti: Object.keys(jobsListNoti).length,
      messageCompanyNoti: messages_combined_active.length,
      followersNoti: Object.keys(followersNoti).length,
      matchedForCandidate: matchedProfile[pathOr('1', ['uid'], authenticated)],
      followForCandidate: candidateFollowYou[pathOr('1', ['uid'], authenticated)],
		};
	}),
)(withNamespaces()(Companyprofile));
