import createHistory from 'history/createBrowserHistory'
import createMemoryHistory from 'history/createMemoryHistory'
import * as ReactGA from 'react-ga';

const history = process.env.NODE_ENV === 'test' ? createMemoryHistory() : createHistory()

const isStaging = window.ENVIRONMENT === "staging";

ReactGA.initialize('UA-143870957-1', {
  debug: isStaging,
  titleCase: false,
});

ReactGA.pageview(window.location.pathname + window.location.search);

history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

export default history
