import './components/common.css'
import React, { Component } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import DefaultLayout from './components/layouts/DefaultLayout'
import AdminLayout from './components/layouts/AdminLayout'
import routes from './routes'

// import i18n (needs to be bundled ;))
import './i18n';

class App extends Component {

  getRoute = (route) => {
    return <DefaultLayout {...route}/>;
  }

  getAdminRoute = (route) => {
    return <AdminLayout {...route}/>;
  }

  render() {
    return (
      <div className="app">
        <Switch>
          {routes.map(route =>  route.admin ? this.getAdminRoute(route) : this.getRoute(route))}
          <Redirect path="*" to="/"/>
        </Switch>
      </div>
    )
  }
}

export default App
