import React, { Component } from 'react'
import { Modal, Form, Tag, Icon, Input, Button, Select, Row, Col, AutoComplete, Checkbox, notification, InputNumber } from 'antd'
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import algoliaENV from '../../../algolia'
import RichTextEditor from 'react-rte';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';

const client = algoliaENV;
const index = client.initIndex('education');

const { Option } = Select;
const { TextArea } = Input;
const InputGroup = Input.Group;
let id = 0;
let requiredFields = ['job-title', 'job-description', 'seniority', 'contact-person', 'contact-email', 'prefix', 'contact-number' ]

class addJobs extends Component {
  constructor(props){
    super(props);

    let jobDescEditor = RichTextEditor.createEmptyValue()
    if(props.editData){
      jobDescEditor = RichTextEditor.createValueFromString(props.editData.job_description, 'html');
    }

    this.state = {
      dataSourceBraSec: [],
      dataSourceEdu: [],
      dataSourceSkills: [],
      tagsBraSec: [],
      tagsEdu: [],
      tagsSkills: [],
      inputValueBraSec: '',
      inputValueEdu: '',
      inputValueSkills: '',
      editId_state: false,
      salary_experience_status: false,
      editorError: false,
      editorValue: jobDescEditor,
      city: '',
      _geoloc: {
        "lat": 0,
        "lng": 0
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.visible !== this.props.visible){
    const { tags_branches_sectors, tags_skills, editData, editId } = nextProps;
    const { editId_state } = this.state
    const { form } = this.props;
    let tempBraSec = []
    let tempTags = []
    let jobDescEditor = ""
    Object.keys(tags_branches_sectors).map(function (key) {
      return tempBraSec.push(tags_branches_sectors[key].name);
    });
    Object.keys(tags_skills).map(function (key) {
      return tempTags.push(tags_skills[key].name);
    });
    if (editId !== editId_state) {
      this.setState({
        tagsSkills: [],
        tagsBraSec: [],
        tagsEdu: [],
        city: ''
      })
    }
    setTimeout(
      function () {
        form.resetFields();
        this.setState({
          dataSourceBraSec: tempBraSec,
          dataSourceSkills: tempTags,
          tagsBraSec: editId ? this.arrayUnique(Object.values(pathOr([], ['branch_sector'], editData)).concat(this.state.tagsBraSec)) : this.state.tagsBraSec,
          // tagsEdu: editId ? this.arrayUnique(Object.values(pathOr([], ['education'], editData)).concat(this.state.tagsEdu)) : this.state.tagsEdu,
          tagsSkills: editId ? this.arrayUnique(Object.values(pathOr([], ['skills'], editData)).concat(this.state.tagsSkills)) : this.state.tagsSkills,
          editId_state: editId,
          salary_experience_status: pathOr(false, ['salary_experience'], editData),
          _geoloc: {
            "lat": editData ? pathOr(0, ['_geoloc', 'lat'], editData) : 0,
            "lng": editData ? pathOr(0, ['_geoloc', 'lng'], editData) : 0
          },
          city: editData ? pathOr('', ['work_location'], editData) : '',
          editorValue: editId ? RichTextEditor.createValueFromString(pathOr("", ['job_description'], editData), 'html') : RichTextEditor.createValueFromString(" ", 'html'), //comment to fixed description bugs, not sure if this will break anything
        });
      }
        .bind(this),
      200
    );
    }
  }

  arrayUnique = (array) => {
    var a = array.concat();
    for (var i = 0; i < a.length; ++i) {
      for (var j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j])
          a.splice(j--, 1);
      }
    }

    return a;
  }

  handleSubmit = (e) => {
    const {t} = this.props;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { tagsBraSec, tagsSkills, editorValue, city, _geoloc } = this.state
        if(!editorValue.getEditorState().getCurrentContent().hasText()) {
          this.setState({
            editorError: true
          })
        } else {
          this.props.handleOk({...values, 'work-location': city, _geoloc, 'job-description': editorValue.toString('html')}, tagsBraSec, tagsSkills, this.props.form)
          setTimeout(
            function () {
              this.setState({
                tagsSkills: [],
                tagsBraSec: [],
                tagsEdu: []
              })
            }
            .bind(this),
            1000
          );
        }
      }else{
        notification.error({
          message: t('Please correct the fields highlighted to continue')
        });
      }
    });
  }

  handleCloseBraSec = (removedTag) => {
    const tagsBraSec = this.state.tagsBraSec.filter(tag => tag !== removedTag);
    this.setState({ tagsBraSec });
  }

  handleCloseSkills = (removedTag) => {
    const tagsSkills = this.state.tagsSkills.filter(tag => tag !== removedTag);
    this.setState({ tagsSkills });
  }

  onSelectBraSec = (value) => {
    this.setState({ inputValueBraSec: value });
    setTimeout(
      function () {
        this.handleInputConfirmBraSec()
      }
        .bind(this),
      200
    );
  }

  onSelectSkills = (value) => {
    this.setState({ inputValueSkills: value });
    setTimeout(
      function () {
        this.handleInputConfirmSkills()
      }
        .bind(this),
      200
    );
  }

  handleInputConfirmBraSec = () => {
    const state = this.state;
    const inputValueBraSec = state.inputValueBraSec;
    let tagsBraSec = state.tagsBraSec;
    if (inputValueBraSec && tagsBraSec.indexOf(inputValueBraSec) === -1) {
      tagsBraSec = [...tagsBraSec, inputValueBraSec];
    }

    this.props.form.setFieldsValue({
      'branch-sector': null
    })
    this.setState({
      tagsBraSec,
      inputValueBraSec: '',
    });
  }

  handleInputConfirmSkills = () => {
    const state = this.state;
    const inputValueSkills = state.inputValueSkills;
    let tagsSkills = state.tagsSkills;
    if (inputValueSkills && tagsSkills.indexOf(inputValueSkills) === -1) {
      tagsSkills = [...tagsSkills, inputValueSkills];
    }

    this.props.form.setFieldsValue({
      'skills': null
    })
    this.setState({
      tagsSkills,
      inputValueSkills: '',
    });
  }

  validateBrachSector = (rule, value, callback) => {
    const { t } = this.props;
    if (this.state.tagsBraSec.length > 0) {
      callback()
    } else {
      callback(t('Please input your company branch/sectior!'))
    }
  }

  hasErrors(fieldsValue) {
    const { tagsBraSec, editorValue } = this.state;
    let nowHasError = false;
    requiredFields.map(fieldName => {
      if(
        fieldsValue[fieldName] == "" ||
        fieldsValue[fieldName == undefined] ||
        tagsBraSec.length <= 0 || !editorValue.getEditorState().getCurrentContent().hasText()
        ) nowHasError = true;
    })
    return nowHasError;
    // return Object.keys(fieldErrors).some(field => fieldErrors[field]);
  }

  componentDidMount = () => {
    index.searchForFacetValues(
      {
        facetName: 'name',
        facetQuery: '',
      },
      (err, content) => {
        if (err) throw err;
        // this.add();
        this.setState({
          dataSourceEdu: Object.values(content.facetHits).map(item => { return item.value })
        })
      }
    );
  }

  searchOnChange = (value) => {
    index.searchForFacetValues(
      {
        facetName: 'name',
        facetQuery: value,
      },
      (err, content) => {
        if (err) throw err;
        this.setState({
          dataSourceEdu: Object.values(content.facetHits).map(item => { return item.value })
        })
      }
    );
  }

  salary_experience = (e) => {
    const { form: { setFieldsValue } } = this.props
    setFieldsValue({'salary-start': ""})
    setFieldsValue({'salary-end': ""})
    const value = e.target.checked;
    this.setState({
      salary_experience_status: value
    })
  }

  onEditorChange = (value) => {
    this.setState({editorValue: value});
  };

  IsInRange0100 = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value >= 0 && value <= 100) {
      callback();
    }else{
      callback('Area code should be 0-100');
    }
  };

  Is15Char = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value.toString().length <= 15) {
      callback();
    }else{
      callback("Number Shouldn't be longer than 15 digits");
    }
  };

  numberWithCommas = (value) => {
    let replaceWithComma = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return replaceWithComma.replace(/,/g, '.');
  }
  parseNumber = (value) => {
    let temp = value.replace(/\./g, "");
    if(isNaN(parseInt(temp.replace(" ", "")))) return 0
    return parseInt(temp.replace(" ", ""))
  }

  closeModal = () => {
    const { handleCancel } = this.props;
    handleCancel();
  }

  locationAutocomplete = (values) => {
    geocodeByAddress(values.description)
    .then(results => getLatLng(results[0]))
    .then(({ lat, lng }) =>
      this.setState({
        city: values.description,
        _geoloc: {
          "lat": lat,
          "lng": lng
        }
      })
    );
  }

  remove = k => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  educationTypeSelect = (value, stateName, counter) => {
    const { setFieldsValue } = this.props.form;
    if(value === 'HAVO' || value === 'VMBO' || value === 'VWO'){
      this.setState({
        [stateName]: true,
        [stateName+'_selected']: true
      })
      const temp = {};
      temp[`education[${counter}]`] = 'Voortgezet onderwijs';
      setFieldsValue(temp);
    }else{
      this.setState({
        [stateName]: false,
        [stateName+'_selected']: true
      })
      const temp = {};
      temp[`education[${counter}]`] = '';
      setFieldsValue(temp);
    }
  }

  onFocusLocation = event => {
    if(event.target.autocomplete)
    {
      event.target.autocomplete = "whatever";
    }
  };

  render() {
    const { visible, form: { getFieldDecorator, getFieldsValue, getFieldsError, getFieldValue, setFieldsValue }, submitting, t, editData } = this.props;
    const { tagsBraSec, tagsSkills, dataSourceEdu, dataSourceBraSec, tagsEdu, dataSourceSkills, salary_experience_status, editorError, editorValue, city } = this.state;

    getFieldDecorator('keys', { initialValue: [] });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Row gutter={16} key={index}>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 7, offset: 0}} lg={{ span: 6, offset: 0 }} xl={{ span: 5, offset: 0 }}>
          <Form.Item
            label={t("Education Level Minimum")}
            className="modalTagDesgin createJobModalLabel"
            colon={false}
          >
            {getFieldDecorator(`education_level[${k}]`, {
              rules: [
                { required: true, message: t('Please input your education level!') },
              ],
              initialValue: editData ? pathOr('', ['education', index, 'education_level'], editData) : ''
            })(
              <AutoComplete
                dataSource={['VMBO','HAVO','VWO','MBO Niveau 1','MBO Niveau 2','MBO Niveau 3','MBO Niveau 4','HBO', 'WO Bachelor','WO Master','Post-doc','Master']}
                onSelect={(value) => this.educationTypeSelect(value, `level_${k}`, k)}
              >
                <Input placeholder={t("Education Level")} suffix={<Icon type="down" />} />
              </AutoComplete>
            )}
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 0}} lg={{ span: 14, offset: 0 }} xl={{ span: 16, offset: 0 }}>
          <Form.Item
            label={t("Education")}
            className="modalTagDesgin createJobModalLabel"
            colon={false}
          >
            {getFieldDecorator(`education[${k}]`, {
              rules: [
                { required: true, message: t('Please input your education!') },
              ],
              initialValue: this.state[`level_${k}`] ? 'Voortgezet onderwijs' : editData ? pathOr('', ['education', index, 'education'], editData) : ''
            })(
              this.state[`level_${k}`] ?
                <AutoComplete disabled>
                  <Input placeholder={t("Education")} />
                </AutoComplete>
              :
              <AutoComplete
                dataSource={dataSourceEdu}
                onSearch={this.searchOnChange}
                disabled={this.state[`level_${k}_selected`] ? false : true}
              >
                <Input placeholder={t("Education")} suffix={<Icon type="down" />} />
              </AutoComplete>
            )}
          </Form.Item>
        </Col>
        <Col xs={{ span: 24, offset: 0 }} md={{ span: 5, offset: 0}} lg={{ span: 4, offset: 0 }} xl={{ span: 3, offset: 0 }}>
          <Form.Item
            label=" "
            className="modalTagDesgin createJobModalLabel"
            colon={false}
          >
            <div style={{textAlign:'center'}}>
              <Icon className="add-education" onClick={this.add} type="plus-circle" />
              {keys.length > 1 ? (
                <Icon
                  className="remove-education"
                  type="minus-circle"
                  onClick={() => this.remove(k)}
                />
              ) : null}
            </div>
          </Form.Item>
        </Col>
      </Row>
    ));

    if (!Object.keys(getFieldValue('keys')).length) {
      if(pathOr(false, ['education'], editData) && pathOr(false, ['education', 0, 'education'], editData)){
        pathOr([], ['education'], editData).map(() => this.add());
      }else{
        this.add()
      }
    }

    return (
      <Modal
        title={t("Create Job")}
        visible={visible}
        onCancel={this.closeModal}
        footer={null}
        maskClosable={false}
        bodyStyle={{ paddingTop: 0 }}
        width="70%"
        className="createJobModalMain"
      >
        {/* <div onClick={this.add}>test</div> */}
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item
            label={t("Job Title")}
            className="createJobModalLabel"
            colon={false}
          >
            {getFieldDecorator('job-title', {
              rules: [{ required: true, message: t('Please input your job title!') }],
              initialValue: editData ? editData.job_title : ''
            })(
              <Input />
            )}
          </Form.Item>
          {formItems}
          <Form.Item
            label={t("sector_branch")}
            className="modalTagDesgin createJobModalLabel ant-form-item-required"
            colon={false}
          >
            {getFieldDecorator('branch-sector', {
              rules: [
                { required: false, message: t('Please input your company branch/sectior!') },
                { validator: this.validateBrachSector }
              ],
            })(
              <AutoComplete
                dataSource={dataSourceBraSec}
                onSelect={this.onSelectBraSec}
                filterOption={(inputValueBraSec, option) => option.props.children.toUpperCase().indexOf(inputValueBraSec.toUpperCase()) !== -1}
                placeholder=""
              >
                <Input suffix={<Icon type="plus" />} />
              </AutoComplete>
            )}
          </Form.Item>
          {tagsBraSec.map((tag, index) => {
            const tagElem = (
              <Tag className="tagDesign" key={tag} closable={true} onClose={() => this.handleCloseBraSec(tag)}>
                <div className="tabDesigText">{tag}</div>
              </Tag>
            );
            return tagElem;
          })}
          <Form.Item
            label={t("Job Description")}
            className="createJobModalLabel ant-form-item-required"
            colon={false}
          >

              <RichTextEditor
                value={editorValue}
                onChange={this.onEditorChange}
                className="desc-editor"
              />
              {
                editorError &&
                <p>Please fill in Job Description</p>
              }
          </Form.Item>
          <Form.Item
            label="Skills"
            className="modalTagDesgin createJobModalLabel"
            colon={false}
          >
            {getFieldDecorator('skills', {
              rules: [
                { required: false, message: t('Please input your company skills') },
                // { validator: this.validateSkills }
              ],
            })(
              <AutoComplete
                dataSource={dataSourceSkills}
                onSelect={this.onSelectSkills}
                filterOption={(inputValueSkills, option) => option.props.children.toUpperCase().indexOf(inputValueSkills.toUpperCase()) !== -1}
                placeholder=""
              >
                <Input suffix={<Icon type="plus" />} />
              </AutoComplete>
            )}
          </Form.Item>
          {tagsSkills.map((tag, index) => {
            const tagElem = (
              <Tag className="tagDesign" key={tag} closable={true} onClose={() => this.handleCloseSkills(tag)}>
                <div className="tabDesigText">{tag}</div>
              </Tag>
            );
            return tagElem;
          })}
          <Row gutter={16}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={t("Seniority")}
                className="createJobModalLabel"
                colon={false}
              >
                {getFieldDecorator('seniority', {
                  rules: [{ required: true, message: t('Please input your seniority!') }],
                  initialValue: editData ? editData.seniority : ''
                })(
                  <Select size="large">
                    <Option value="starter">{t('Starter')}</Option>
                    <Option value="junior">{t('Junior')}</Option>
                    <Option value="medior">{t('Medior')}</Option>
                    <Option value="senior">{t('Senior')}</Option>
                    <Option value="expert">{t('Expert')}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
            <div style={{float:'right', position:'absolute', right:'10px'}}>
              <Form.Item
                  label=""
                  colon={false}
                  style={{color:'#909090'}}
                >
                  {t("Salary is based on experience")}
                  {getFieldDecorator('salary-experience', {
                    rules: [{ required: false}],
                    initialValue: editData ? editData.salary_experience : ''
                  })(
                    <Checkbox checked={this.state.salary_experience_status} onChange={this.salary_experience} style={{marginLeft:'5px'}} />
                  )}
                </Form.Item>
            </div>
              <Form.Item
                label={t("Salary")}
                style={{ marginBottom: 0 }}
                className="createJobModalLabel"
                colon={false}
              >
                <InputGroup compact>
                  {getFieldDecorator('salary-start', {
                    rules: [{ required: false, message: t('Please input your min salary!') }],
                    initialValue: editData ? editData.salary_start : ''
                  })(
                    <InputNumber
                      disabled={salary_experience_status}
                      style={{ width: '45%', textAlign: 'center', height: '45px' }}
                      formatter={value => this.numberWithCommas(value)}
                      parser={value => this.parseNumber(value)}
                      placeholder="Min €"
                    />
                  )}
                  <Input
                    style={{
                      width: '10%', borderLeft: 0, pointerEvents: 'none', backgroundColor: '#fff',
                    }}
                    placeholder="~"
                    disabled
                  />
                  {getFieldDecorator('salary-end', {
                    rules: [{ required: false, message: t('Please input your max salary!') }],
                    initialValue: editData ? editData.salary_end : ''
                  })(
                    <InputNumber
                      disabled={salary_experience_status}
                      style={{ width: '45%', textAlign: 'center', borderLeft: 0, height: '45px' }}
                      formatter={value => this.numberWithCommas(value)}
                      parser={value => this.parseNumber(value)}
                      placeholder="Max €"
                    />
                  )}
                </InputGroup>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={t("Employment Type")}
                className="createJobModalLabel"
                colon={false}
              >
                {getFieldDecorator('employment_type', {
                  rules: [{ required: false, message: t('Please input your employment type!') }],
                  initialValue: editData ? editData.employment_type : ''
                })(
                  <Select size="large">
                    <Option value={t('full-time')}>{t('full-time')}</Option>
                    <Option value={t('part-time')}>{t('part-time')}</Option>
                    <Option value={t('interim')}>{t('interim')}</Option>
                    <Option value={t('freelance')}>{t('freelance')}</Option>
                    <Option value={t('self-employed')}>{t('self-employed')}</Option>
                    <Option value={t('internship')}>{t('internship')}</Option>
                    {/* <Option value={t('side-job')}>{t('side-job')}</Option> */}
                    <Option value={t('voluntary')}>{t('voluntary')}</Option>
                    <Option value={t('remote (Thuiswerkend)')}>{t('remote (Thuiswerkend)')}</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={t("Work Location")}
                className="createJobModalLabel"
                colon={false}
              >
                <GooglePlacesAutocomplete
                  onSelect={this.locationAutocomplete}
                  initialValue={editData ? city !== '' ? city : editData.work_location : ''}
                  required
                  renderInput={(props) => (
                    <div className="custom-wrapper">
                      <Input autoComplete="off" onFocus={this.onFocusLocation} {...props} />
                    </div>
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={t("Contact Person")}
                className="createJobModalLabel"
                colon={false}
              >
                {getFieldDecorator('contact-person', {
                  rules: [{ required: true, message: t('Please input your contact person!') }],
                  initialValue: editData ? editData.contact_person : ''
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={t("Contact Email")}
                className="createJobModalLabel"
                colon={false}
              >
                {getFieldDecorator('contact-email', {
                  rules: [{
                    type: 'email',
                    message: t('The input is not valid E-mail!'),
                  },{ required: true, message: t('Please input your contact email!') }],
                  initialValue: editData ? editData.contact_email : ''
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>
              <Form.Item
                label={t("Contact Number")}
                className="createJobModalLabel"
                colon={false}
              >
                <InputGroup>
                  <Row gutter={10}>
                    <Col span={8}>
                      {getFieldDecorator('prefix', {
                        rules: [
                          {
                            required: true
                          },
                          {
                            validator: this.IsInRange0100,
                          }
                      ],
                        initialValue: editData ? editData.contact_prefix : '+31'
                      })(
                        <Input placeholder={t("Area Code")} />
                      )}
                    </Col>
                    <Col span={16}>
                      {getFieldDecorator('contact-number', {
                        rules: [
                          {
                            required: true
                          },{
                            validator: this.Is15Char,
                          }
                        ],
                        initialValue: editData ? editData.contact_number : ''
                      })(
                        <Input type="number" placeholder={t("Phone Number")} />
                      )}
                    </Col>
                  </Row>
                </InputGroup>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 12, offset: 0 }}>

            </Col>
          </Row>

          <hr />
          <div className="addJobModalButtons">
            <Button className="saveJobModal" type="primary-global bordered" onClick={this.closeModal}>{t("cancel")}</Button>
            <Button className={this.hasErrors(getFieldsValue()) ?
              "saveJobModal saveJobModalDisabled"
              : "saveJobModal"} type="primary-global"
              loading={submitting} htmlType="submit"
              disabled={this.hasErrors(getFieldsValue())}>{t("done")}</Button>
          </div>
        </Form>
      </Modal>
    )
  }
}

// export default ((Form.create())(addJobs))
// export default connect(null, null)(Companyprofile);
export default compose(
  firestoreConnect((props, state) => {
    return [
      {
        collection: 'branches_sectors',
      },
      {
        collection: 'skills',
      }
    ]
  }),
  connect((state, props) => {
    const { data } = pathOr('', ['firestore'], state)
    return {
      tags_branches_sectors: pathOr('', ['branches_sectors'], data),
      tags_skills: pathOr('', ['skills'], data)
    };
  }),
)((Form.create())(addJobs));
