import './header.css';
import React, { Component } from "react";
import { Layout, Menu, Icon, Row, Col, Select, Input, Tag, Button, Badge, Tooltip, AutoComplete, Modal } from 'antd';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { signout } from '../../containers/auth/actions';
import { Link, withRouter } from 'react-router-dom';
import { pathOr } from 'ramda';
import algoliaENV from '../../algolia'
import {matched_by_job} from '../../containers/companyprofile/companyprofile'
import {random_self_job} from '../../containers/applicationmanagement/applicationmanagement'
import MyWindowPortal from '../../containers/message/components/MyWindowPortal';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl-be';
import {domainUrl} from "../../firebase";
import mp3_ringtone from './mp3/0375.mp3';


moment.locale('nl-be');

const { Header: AntHeader } = Layout;
const Search = Input.Search;
const SubMenu = Menu.SubMenu;
const Option = Select.Option;
export let random_messages = Math.random();
export let random_notification = Math.random();
export let notification_all = Math.random();
export let jobManagementNoti = false;
export let profileManagementNoti = false;
export let jobMatched = false;
export let msgNoti = 0;
export let appNoti = 0;
const client = algoliaENV;
let feedURL = "/images/icon/Feed Outline.svg"
let jobURL = "/images/icon/Message Smaller.svg"
export class Header extends Component {
  state = {
    searchBy: 'jobs',
    suggestionShow: false,
    selectedTags: [],
    suggestionBox: false,
    jobTitleSearch: '',
    messageNoti: 0,
    managementNoti: 0,
    visibleLogs: false,
    isCalling: false,
    MessageId: false,
    callVisible: false,
    applicantID: false
  }
  searchByChange = (value) => {
    this.setState({
      searchBy: value,
      selectedTags: [],
      suggestionShow: false,
      // suggestionBox: false
    }, () => this.searchOnChange())
  }
  searchBlur = () => {
    this.setState({
      suggestionBox: false
    })
  }
  searchFocus = () => {
    const { searchBy } =  this.state;
    if(searchBy !== "jobs"){
      this.setState({
        suggestionBox: true
      })
      this.searchOnChange();
    }
  }
  searchOnChange = (event) => {
    let value = pathOr('', ['target', 'value'], event)
    const { searchBy } = this.state
    const { current_user } = this.props
    if(searchBy !== 'jobs'){
      const index = client.initIndex(current_user && current_user.role === 'company' ? 'users' : (searchBy === "company" ? 'company' : 'job_posting' ));
      const this_function = this;
      index.searchForFacetValues(
        {
          facetName: current_user && current_user.role === 'company' ? searchBy === 'education' ? 'profile.education.education_name' : 'profile.'+searchBy : (searchBy === "company" ? 'companyname' : searchBy),
          facetQuery: value,
        },
        function searchDone(err, content) {
          if (err) throw err;
          this_function.setState({
            suggestionShow: content.facetHits
          })
        }
      );
    }
  }
  addTags = (value) => {
    let { selectedTags, suggestionShow, searchBy } = this.state;
    const combined_array = [...selectedTags, value];
    if(searchBy !== 'preferred_job' && searchBy !== 'education'){
      this.setState({
        selectedTags: combined_array.filter((v,i) => combined_array.indexOf(v) === i)
      })
    }else{
      this.setState({
        selectedTags: combined_array.filter((v,i) => combined_array.indexOf(v) === i)
      }, () => {
        this.goToSearch()
      })
    }
  }
  clearTags = () => {
    this.setState({selectedTags: []})
  }
  handleCloseEdu = (removedTag) => {
    const selectedTags = this.state.selectedTags.filter(tag => tag !== removedTag);
    this.setState({ selectedTags });
  }
  goToSearch = () => {
    const { current_user } = this.props
    const { selectedTags, searchBy } = this.state;
    let indexBy = current_user&& current_user.role === 'company'
      ? 'users'
      : (searchBy === "company" ? 'company' : 'job_posting' )
    const index = client.initIndex(indexBy);
    const filter = [];
    this.setState({
      suggestionBox: false
    });
    selectedTags.forEach(function(tag) {
      filter.push((current_user && current_user.role === 'company' ? searchBy === 'education' ? 'profile.education.education_name' : 'profile.'+searchBy : (searchBy === "company" ? "companyname" : searchBy )) +":"+tag)
    });
    index.search("",
      {
        "facetFilters": [filter],
        "numericFilters": [
          "status !=0"
         ]
      },
      (err, content) => {
        if (err) throw err;
        this.props.history.push({
          pathname: '/search' + (searchBy === "company" ? '/company' : "/") + '?searchBy='+searchBy+'&selectedTags='+encodeURIComponent(selectedTags),
          searchResults: content.hits
        })
      }
    );
  }
  searchByJobTitle = (value) => {
    const { searchBy } = this.state;
    const { current_user } = this.props
    const this_state = this;
    let filterDisabledJobs = '';
    Object.keys(pathOr({}, ['disabledJobs'], current_user)).forEach((i,index,user) => {
      if (index === Object.keys(pathOr({}, ['disabledJobs'], current_user)).length - 1){
        filterDisabledJobs += 'NOT objectID:'+ user[index];
      }else{
        filterDisabledJobs += 'NOT objectID:'+ user[index] +' AND ';
      }
    })
    if(searchBy === "jobs"){
      const index = client.initIndex(current_user && current_user.role === 'company' ? 'users' : 'job_posting');
      index.search(value,
      {
        "restrictSearchableAttributes": current_user && current_user.role === 'company' ? (value ? "profile.current_job" : "") : (value ? "job_title" : ""),
        'filters': filterDisabledJobs,
        "numericFilters": [
          "status !=0"
         ]
      },
      function searchDone(err, content) {
        if (err) throw err;
        this_state.props.history.push({
          pathname: '/search/?searchBy='+searchBy+'&jobTitleSearch='+value,
          searchResults: content.hits
        })
      }
    );
    }
  }
  componentDidMount(){
    random_messages = Math.random();
    notification_all = Math.random();
    random_notification = Math.random();

    const {auth, firestore} = this.props
    if(auth && auth.uid){
      firestore.collection('users').doc(auth.uid).get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          if(doc.data() && doc.data().statusLogs){
            this.setState({
              visibleLogs: true
            })
          }
        }
      })
      .catch(err => {
        console.log('Error getting document', err);
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {auth, current_user} = nextProps;
    const jobsList = [];
    let profileValue = false;
    let profileCandidateValue = false;
    let candidateNoti = 0;
    let messages_combined_active = [];
    if (nextProps.header_message) {
      if(!nextProps.company_id){
        Object.entries(pathOr({}, ['header_message'], nextProps))
        .filter(item => {
          return item[1].candidate_id === auth.uid
        })
        .filter(item => {
          return item[1].status === true
        }).map(item => {
          const lastMsg = pathOr([], [1, 'chat', pathOr([], [1, 'chat'], item).length - 1], item)
          if(pathOr(false, ['message'], lastMsg) === 'call' && pathOr(false, ['status'], lastMsg) === 'calling' && pathOr(false, ['sender_id'], lastMsg) !== auth.uid ){
            this.setState({
              isCalling: true,
              MessageId: pathOr(false, [1, 'id'], item),
              callVisible: true,
              applicantID: pathOr(false, [1, 'company_id'], item)
            })
          }else if(this.state.MessageId === pathOr(false, [1, 'id'], item)){
            this.setState({
              isCalling: false,
              MessageId: false,
              callVisible: false,
              applicantID: false
            })
          }
        })

        messages_combined_active = Object.entries(pathOr({}, ['header_message'], nextProps))
        .filter(item => {
          return item[1].candidate_id === auth.uid
        })
        .filter(item => {
          return item[1].status === true
        })
        .filter(item => {
          return item[1][auth.uid] === true
        })
      }
      if(nextProps.company_id){

        Object.entries(pathOr({}, ['header_message'], nextProps))
        .filter(item => {
          return item[1].company_id === nextProps.company_id
        })
        .filter(item => {
          return item[1].status === true
        }).map(item => {
          const lastMsg = pathOr([], [1, 'chat', pathOr([], [1, 'chat'], item).length - 1], item)
          if(pathOr(false, ['message'], lastMsg) === 'call' && pathOr(false, ['status'], lastMsg) === 'calling' && pathOr(false, ['sender_id'], lastMsg) !== nextProps.company_id ){
            this.setState({
              isCalling: true,
              MessageId: pathOr(false, [1, 'id'], item),
              callVisible: true,
              applicantID: pathOr(false, [1, 'candidate_id'], item)
            })
          }else if(this.state.MessageId === pathOr(false, [1, 'id'], item)){
            this.setState({
              isCalling: false,
              MessageId: false,
              callVisible: false,
              applicantID: false
            })
          }
        })

        messages_combined_active = Object.entries(pathOr({}, ['header_message'], nextProps))
        .filter(item => {
          return item[1].company_id === nextProps.company_id
        })
        .filter(item => {
          return item[1].status === true
        })
        .filter(item => {
          return item[1][nextProps.company_id] === true
        })
      }

      msgNoti = messages_combined_active.length
      setTimeout(
      function() {
        this.setState({
          messageNoti: messages_combined_active.length
        });
      }
      .bind(this),
        200
      );
    }
    if (nextProps.header_notification_all) {
      if(!nextProps.company_id){
        Object.entries(nextProps.header_notification_all).map(item => {
          if(item[0] === auth.uid){
            candidateNoti = item[1];
          }
        });
        if(pathOr(false, ['header_notification_all', auth.uid], nextProps)){
          profileCandidateValue = Object.keys(pathOr([], ['header_notification_all', auth.uid], nextProps)).length > 0;
        }
      }
      if(nextProps.company_id){
        Object.entries(pathOr({}, ['header_job_posting'], nextProps)).filter(item => {return item[1].status !== 0}).map(item=>{
          if(Object.keys(nextProps.header_notification_all).includes(item[0])){
            if(nextProps.header_notification_all[item[0]].matched || nextProps.header_notification_all[item[0]].applied){
              jobsList[item[0]] = pathOr([], ['header_notification_all', item[0]], nextProps)
            }
          }
        });
        if(pathOr(false, ['header_notification_all', nextProps.company_id], nextProps)){
          profileValue = Object.keys(pathOr([], ['header_notification_all', nextProps.company_id], nextProps)).length > 0;
        }
      }
      appNoti = nextProps.company_id ? profileValue ? Object.keys(jobsList).length + 1 : Object.keys(jobsList).length  : Object.keys(candidateNoti).length;
      setTimeout(
        function() {
          jobManagementNoti = nextProps.company_id ? jobsList : candidateNoti;
          profileManagementNoti = nextProps.company_id ? profileValue : profileCandidateValue;
          this.setState({
            managementNoti: nextProps.company_id ? profileValue ? Object.keys(jobsList).length + 1 : Object.keys(jobsList).length  : Object.keys(candidateNoti).length,
          })
        }
      .bind(this), 200);
    }
  }

  handleCancelLogs = () => {
    const {firestore, auth } = this.props;
    firestore.collection('users').doc(auth.uid).set({ 'statusLogs':  false}, { merge: true });
    this.setState({
      visibleLogs: false
    })
  }

  handleOkLogs = () => {
    const {firestore, auth } = this.props;
    firestore.collection('users').doc(auth.uid).set({ 'statusLogs':  false}, { merge: true });
    this.setState({
      visibleLogs: false
    })
  }

  acceptCall = () => {
    const {firestore, company_id, auth} = this.props;
    const {MessageId} = this.state;
    window.open(`${domainUrl}/video-call/${MessageId}/${company_id ? company_id : auth.uid}`, '', 'width=800,height=450,left=200,top=200')
    firestore.collection('messages')
    .doc(MessageId)
    .get()
    .then(doc => {
      if (!doc.exists) {
        console.log('No matching documents.');
        return;
      }
      const allData = doc.data();
      allData.chat[allData.chat.length-1].status = "Accepted"
      firestore
      .collection('messages')
      .doc(doc.id)
      .set(
        {
          chat: [...allData.chat]
        },
        { merge: true }
      )
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
    this.setState({
      callVisible: false
    })
  }

  rejectCall = () => {
    const {firestore} = this.props;
    const {MessageId} = this.state;
    firestore.collection('messages')
    .doc(MessageId)
    .get()
    .then(doc => {
      if (!doc.exists) {
        console.log('No matching documents.');
        return;
      }
      const allData = doc.data();
      allData.chat[allData.chat.length-1].status = "Rejected"
      firestore
      .collection('messages')
      .doc(doc.id)
      .set(
        {
          chat: [...allData.chat]
        },
        { merge: true }
      )
    })
    .catch(err => {
      console.log('Error getting documents', err);
    });
    this.setState({
      callVisible: false
    })
  }
  render() {
    const { signout, auth, company_id, current_user, match, t, company_data, subscriptions, header_message, headerCandidateUsers } = this.props;
    const { suggestionShow, selectedTags, suggestionBox, searchBy, messageNoti, managementNoti, isCalling, MessageId, callVisible, applicantID, isActivecall, visibleLogs } = this.state; //messageNoti variable state already contain number of unread message count
    const authenticated = auth.isLoaded && !auth.isEmpty;
    return (
      <Row>
        <Modal
          closable={false}
          visible={callVisible}
          maskClosable={false}
          footer={null}
          width="300px"
          centered={true}
          destroyOnClose={true}
          wrapClassName="parentModalCalling"
        >
          <div className="mainModalCalling">
            <audio loop src={mp3_ringtone} autoPlay/>
            <img src={!company_id ? pathOr('', [applicantID, 'company_logo'], company_data) : pathOr('', [applicantID, 'profile', 'profilePicture'], headerCandidateUsers)} />
            <p>{!company_id ? pathOr('', [applicantID, 'companyname'], company_data) + ' is calling...' : pathOr('', [applicantID, 'first_name'], headerCandidateUsers) + t(' is calling...')}</p>
            <div className="appointment-btn-wrapper">
              <div onClick={this.acceptCall} className="tc-accept-button">{t('Accept')}</div>
              <div onClick={this.rejectCall} className="tc-reject-button">{t('Reject')}</div>
            </div>
          </div>
        </Modal>
        <Col xs={0} sm={0} md={0} lg={24}>
          <Modal
            title="Goed nieuws, unlocQed is geüpdatet!"
            visible={visibleLogs}
            maskClosable={false}
            width="70%"
            centered
            footer={[
              <Button className="form-button" key="submit" type="primary-global" onClick={this.handleOkLogs}>{t('Continue')}</Button>,
            ]}
            onCancel={this.handleCancelLogs}
          >
            <h2>Welkom op een nieuwe versie van unlocQed. Hieronder staat een overzicht van de verbeteringen:</h2>
            <ul>
              <li>Het is nu mogelijk om sollicitaties te doen via unlocQed middels een videogesprek! Probeer het in de chat. </li>
              <li>Het is nu makkelijker en sneller om je eigen videopitch te uploaden als kandidaat.</li>
              <li>Er zijn een aantal tekstuele verbeteringen gedaan in de lijst met skills en opleidingsniveaus.</li>
              <li>Sommige elementen op de homepagina zijn nu beter te lezen.</li>
              <li>Er zijn een aantal UX/UI verbeteringen gedaan in het scherm waar kandidaten hun profiel kunnen aanpassen.</li>
              <li>Het is nu duidelijker dat het opgeven van een telefoonnummer niet verplicht is voor de registratie van een account.</li>
              <li>Er zijn een aantal visuele verbeteringen gedaan door heel het platform.</li>
            </ul>
            We zijn druk bezig met ontwikkelen van nieuwe functionaliteiten en het oplossen van bugs. We willen graag iedereen danken voor het gebruiken van unlocQed en we hopen dat je een positieve ervaring hebt. Mocht er iets zijn waarmee wij het platform kunnen verbeteren, twijfel dan niet om je feedback achter te laten via de feedbackknop rechts in beeld. Bedankt!
          </Modal>
          <div onClick={this.searchBlur} className={"headerSearchOverlay" + (suggestionBox && " headerSearchOverlayDisplay") }></div>
          <AntHeader style={{
            background: '#F3F3F3',
            padding: 0,
            height: 54,
            position: 'fixed',
            zIndex: 9999,
            width: '100%',
            borderBottom: '1px solid #e8e8e8',
            boxShadow: '-1px 2px 7px 0px #888888'
          }} className="main-ant-header">
            <Row>
              <Col xs={{ span: 12, offset: 0 }} lg={{ span: 4, offset: 0 }} style={{display:'flex'}}>
                  <Link to={current_user && current_user.terms_and_conditions && auth.emailVerified ? current_user.role === 'company' ?  '/companyprofile/'+current_user.company_id : !authenticated ? '/' : '/dashboard' : '/'}>
                    <div className="logo" />
                  </Link>
              </Col>
              {((authenticated && current_user && current_user.terms_and_conditions && auth.emailVerified) || (!authenticated && ['/termsandconditions', '/emailverify', '/forgetpassword', '/action','/signup','/login'].indexOf(match.path) === -1)) &&
              <Col  style={match.path === '/search/:saerchtype?' ? {visibility: 'hidden'} : {}} className="headerSearchBar" xs={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} lg={{ span: 15, offset: 0 }}>
            { /* For Small Screen Size (Mobile), this should be hidden as new row will be added  */}
              {current_user && current_user.role === "company" ?
                <Select onChange={this.searchByChange} className="searchSelect" style={pathOr(false, ['role'], current_user) !== 'company' ? {width: 105} : {width: 152}} defaultValue="jobs" dropdownClassName="searchDropDown" >
                  <Option value="jobs">{t('Current job')}</Option>
                  <Option value="preferred_job">{t('Preferred job')}</Option>
                  <Option value="branch">{t('just_sector')}</Option>
                  <Option value="education">{t('Education')}</Option>
                </Select>
                :
                <Select onChange={this.searchByChange} className="searchSelect" style={pathOr(false, ['role'], current_user) !== 'company' ? {width: 105} : {width: 152}} defaultValue="jobs" dropdownClassName="searchDropDown" >
                  <Option value="jobs">{t('Jobs')}</Option>
                  <Option value="company">{t('Companies')}</Option>
                </Select>
              }
                <div className="searchModal" style={pathOr(false, ['role'], current_user) !== 'company' ? {width: 'calc(100% - 105px)'} : {width: 'calc(100% - 152px)'}}>
                  <Search
                    placeholder={t(current_user && current_user.role === 'company' ? "Search here for candidates" : "Search here for company or job vacancy")}
                    className="searchInput"
                    onSearch={value => this.searchByJobTitle(value)}
                    onChange={ this.searchOnChange }
                    onFocus={this.searchFocus}
                  />
                  {suggestionBox &&
                  <div className="searchSuggestions">
                    <Col style={{borderRight: searchBy === 'preferred_job' || searchBy === 'education' ? '0px solid' : '1px solid #a7a7a7' }} className="suggestion" xs={{ span: 24, offset: 0 }} lg={{ span: 14, offset: 0 }}>
                      <div className="suggestionTitle">
                        {t("Suggestion")}
                      </div>
                      <div className="suggestionParent">
                        {suggestionShow && suggestionShow.map((suggestion, index) => {
                          if (suggestion && suggestion.hidden !== true ) {
                            return (
                              <div key={index} onClick={() => this.addTags(suggestion['value'])}>
                                {t(suggestion['value'])}
                              </div>
                            )
                          }
                        })}
                      </div>
                    </Col>
                    {searchBy !== 'preferred_job' && searchBy !== 'education' &&
                      <Col className="suggestion" xs={{ span: 24, offset: 0 }} lg={{ span: 10, offset: 0 }}>
                        <div className="suggestionTitle spaceBetween">
                          <span>{t('Selected')} {t(searchBy)}</span>
                          <span onClick={() => this.clearTags()} className="clearBtn">{t('Clear all')}</span>
                        </div>
                        <div className="addedTagsParent">
                          {selectedTags && selectedTags.map((tag, index) => {
                            return (
                              <Tag className="tagDesign" key={tag} closable={true} onClose={() => this.handleCloseEdu(tag)}>
                                <div className="tabDesigText">{t(tag)}</div>
                              </Tag>
                            )
                          })}
                        </div>
                        <Tooltip title="If selected company is empty, search will return all companies" placement="top" style={{zIndex: 9999}}>
                          <Button
                            onClick={this.goToSearch}
                            className="headerSearchButton"
                            type="primary-global"
                          >
                            {t('Search')}
                          </Button>
                        </Tooltip>
                      </Col>
                    }
                  </div>
                  }
                </div>
              </Col>
              }
              <Col xs={{ span: 0, offset: 0 }} lg={{ span: 5, offset: 0 }}>
              {authenticated && current_user && current_user.terms_and_conditions && auth.emailVerified &&
                <div style={{
                  display: 'inline-block',
                  width: 'calc(100% - 75px)'
                }}>
                <Badge className="headerMessageIcon" count={messageNoti}>
                  <Link to={'/message'}>
                    <Tooltip title={t("Chat")}>
                      <Icon style={match.path === '/message/:chatwith?/:jobid?' ? {color: '#DF0E89' }:{}}  type="message"/>
                    </Tooltip>
                  </Link>
                </Badge>
                <Badge className="headerMessageIcon headerTrackJobIcon" count={managementNoti}>
                  <Link to={current_user.role === 'company' ? '/jobmanagement' : '/trackmyjobs'}>
                    <Tooltip title={t("Overzicht")}>
                      <img className="headerTrackJobIconImg" style={match.path === '/jobmanagement' || match.path === '/trackmyjobs' ? {filter: 'invert(16%) sepia(84%) saturate(5260%) hue-rotate(314deg) brightness(90%) contrast(97%)'}:{}} src={jobURL} alt=""/>
                    </Tooltip>
                  </Link>
                </Badge>
                  {current_user.role !== 'company' &&
                    <Link to={'/dashboard'}>
                      <Tooltip title={t("Dashboard")}>
                        <img style={match.path === '/dashboard' ? {filter: 'invert(16%) sepia(84%) saturate(5260%) hue-rotate(314deg) brightness(90%) contrast(97%)'}:{}} className="headerTrainingCenter" src={feedURL} alt=""/>
                      </Tooltip>
                    </Link>
                  }
                </div>
              }
                <Menu
                  onClick={this.handleClick}
                  mode="horizontal"
                  style={{ display: 'inline-block', float: 'right' }}
                  className="headerUserData"
                  subMenuCloseDelay={1}
                >
                  {authenticated && current_user && current_user.terms_and_conditions && auth.emailVerified &&
                    <SubMenu style={{float: 'right', height: '54px', backgroundColor: '#f3f3f3', width: '70px'}} title={<span>{<div className="headerAvatar" style={{backgroundImage: current_user.role !== 'company' ? 'url('+pathOr('/images/user-circle.png', ['profile', 'profilePicture'], current_user)+')' :  'url('+pathOr('/images/user-circle.png', ['company_logo'], company_data)+')' }}></div>}</span>}>
                      {current_user.role === 'company' &&
                          <Menu.Item key="companyprofile">
                            <Link to={`/companyprofile/${company_id}`}>
                              {t('Company Profile')}
                            </Link>
                          </Menu.Item>
                      }
                      {current_user.role === 'company' &&
                      <Menu.Item key="jobmanagement">
                        <Link to={`/jobmanagement`}>
                          {t('Job Management')}
                        </Link>
                      </Menu.Item>
                      }
                      {current_user.role === 'company' &&
                      <Menu.Item key="membership">
                        <Link to={`/membership`}>
                          {t('Membership')} <b style={{color: '#DF0E89'}}>{` (${pathOr('Trial', [pathOr('', ['subscriptionId'], company_data), 'name'], subscriptions)})`}</b>
                        </Link>
                      </Menu.Item>
                      }
                      {current_user.role === 'candidate' &&
                        <Menu.Item key="profilecandidate">
                          <Link to={`/profile/${auth.uid}`}>
                            {t('Profile')}
                          </Link>
                        </Menu.Item>
                      }
                      {current_user.role === 'candidate' &&
                        <Menu.Item key="trackmyjob">
                          <Link to={`/trackmyjobs`}>
                            {t('Track My Jobs')}
                          </Link>
                        </Menu.Item>
                      }
                      <Menu.Divider />
                      <Menu.Item key="logout">
                        {/* <div onClick={() => firebase.auth().signOut().then(() => this.props.history.push('/'))}>Logout</div> */}
                        <div onClick={signout}>{t('Logout')}</div>
                      </Menu.Item>
                    </SubMenu>
                    }
                    {(!authenticated && ['/termsandconditions', '/emailverify', '/forgetpassword', '/action','/signup','/login'].indexOf(match.path) === -1) &&
                      <Menu.Item className="signInHeader">
                        <Link to={'/login'}>
                          <Button type="primary-global small">{t("sign_in")}</Button>
                        </Link>
                      </Menu.Item>
                    }
                </Menu>
              </Col>
            </Row>
          </AntHeader>
        </Col>
        {/*mobile*/}
        <Col xs={24} sm={24} md={24} lg={0}>
          <AntHeader style={{
            background: '#F3F3F3',
            padding: 0,
            height: this.state.suggestionBox ? '100%' : 54,
            position: 'fixed',
            zIndex: 12,
            width: '100%',
            borderBottom: '1px solid #e8e8e8'
          }} className="main-ant-header">
            <Row style={{display:'flex', flexDirection:'column', height:'100%'}}>
              <Col xs={{ span: 12, offset: 0 }} lg={{ span: 4, offset: 0 }} style={{display:'flex', width:'100%', zIndex:99, boxShadow:'0 2px 4px 0 rgba(0,0,0,0.5)', justifyContent:'space-between', height: 53}}>
                  <Link to={current_user && current_user.role === 'company' ?  '/companyprofile/'+current_user.company_id : !authenticated ? '/' : '/dashboard'}>
                    <div className="logo-mobile" />
                  </Link>
                <div className="right-header-mobile-container" style={{display:'flex', alignItems:'center'}}>
                  {current_user && current_user.role !== 'company' &&
                    <Link to={'/dashboard'} style={{display:'flex'}}>
                      <img style={match.path === '/dashboard' ? {filter: 'invert(16%) sepia(84%) saturate(5260%) hue-rotate(314deg) brightness(90%) contrast(97%)'}:{}} className="headerTrainingCenterMobile" src={feedURL} alt=""/>
                    </Link>
                  }
                  {current_user &&
                    <div>
                      <Badge className="headerMessageIconMobile" count={messageNoti}>
                        <Link to={'/message'} style={{display:'flex', paddingLeft:23}}>
                          <Icon style={match.path === '/message/:chatwith?/:jobid?' ? {color: '#DF0E89', fontSize: 22 }:{fontSize: 22}} type="message"/>
                        </Link>
                      </Badge>
                      <Icon
                        style={this.state.suggestionBox ? {marginLeft: 20, marginTop: 25, fontSize: 26, color: '#DF0E89'} : {marginLeft: 20, marginTop: 25, fontSize: 26}}
                        type="search"
                        onClick={() => this.setState({suggestionBox: true})}
                      />
                    </div>
                  }
                  <Menu
                    onClick={this.handleClick}
                    mode="horizontal"
                    style={{ display: 'inline-block', float: 'right' }}
                    className="headerUserData"
                  >
                    {authenticated && current_user && current_user.terms_and_conditions && auth.emailVerified &&
                      <SubMenu style={{float: 'right', height: '53px', backgroundColor: '#f3f3f3', width: '70px'}} title={<span>{<div className="headerAvatar" style={{backgroundImage: current_user.role !== 'company' ? 'url('+pathOr('/images/user-circle.png', ['profile', 'profilePicture'], current_user)+')' :  'url('+pathOr('/images/user-circle.png', ['company_logo'], company_data)+')' }}></div>}</span>}>
                        {current_user.role === 'company' &&
                            <Menu.Item key="companyprofile">
                              <Link to={`/companyprofile/${company_id}`}>
                                {t('Company Profile')}
                              </Link>
                            </Menu.Item>
                        }
                        {current_user.role === 'company' &&
                        <Menu.Item key="jobmanagement">
                          <Link to={`/jobmanagement`}>
                            {t('Job Management')}
                          </Link>
                        </Menu.Item>
                        }
                        {current_user.role === 'company' &&
                        <Menu.Item key="membership">
                          <Link to={`/membership`}>
                            {t('Membership')} <b style={{color: '#DF0E89'}}>{` (${pathOr('Trial', [pathOr('', ['subscriptionId'], company_data), 'name'], subscriptions)})`}</b>
                          </Link>
                        </Menu.Item>
                        }
                        {current_user.role === 'candidate' &&
                          <Menu.Item key="profilecandidate">
                            <Link to={`/profile/${auth.uid}`}>
                              {t('Profile')}
                            </Link>
                          </Menu.Item>
                        }
                        {current_user.role === 'candidate' &&
                          <Menu.Item key="trackmyjob">
                            <Link to={`/trackmyjobs`}>
                              {t('Track My Jobs')}
                            </Link>
                          </Menu.Item>
                        }
                        <Menu.Divider />
                        <Menu.Item key="logout">
                          {/* <div onClick={() => firebase.auth().signOut().then(() => this.props.history.push('/'))}>Logout</div> */}
                          <div onClick={signout}>{t('Logout')}</div>
                        </Menu.Item>
                      </SubMenu>
                    }
                    {(!authenticated && ['/termsandconditions', '/emailverify', '/forgetpassword', '/action','/signup','/login'].indexOf(match.path) === -1) &&
                      <Menu.Item className="signInHeader">
                        <Link to={'/login'}>{t("sign_in")}</Link>
                      </Menu.Item>
                    }
                  </Menu>
                </div>
              </Col>
              {suggestionBox &&
                <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} lg={{ span: 0, offset: 0 }} style={{backgroundColor:'white', height:'100%'}}>
                  {authenticated && current_user && current_user.terms_and_conditions && auth.emailVerified &&
                  <Col className="headerSearchBar" xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} lg={{ span: 0, offset: 0 }} style={match.path === '/search/:saerchtype?' ? {display:'flex', alignItems:'center', visibility: 'hidden'} : {display:'flex', alignItems:'center'}}>
                    <div style={{padding:10}} onClick={this.searchBlur} >
                      <Icon type="arrow-left" />
                    </div>
                    {current_user.role === "candidate" ?
                      <Select onChange={this.searchByChange} className="searchSelect" style={{bottom: 0}} defaultValue="jobs" dropdownClassName="searchDropDown" >
                        <Option value="jobs">{t('Jobs')}</Option>
                        <Option value="company">{t('Companies')}</Option>
                      </Select>
                      :
                      <Select onChange={this.searchByChange} className="searchSelect" style={{bottom: 0}} defaultValue="jobs" dropdownClassName="searchDropDown" >
                        <Option value="jobs">{t('Current job')}</Option>
                        <Option value="preferred_job">{t('Preferred job')}</Option>
                        <Option value="branch">{t('just_sector')}</Option>
                        <Option value="education">{t('Education')}</Option>
                      </Select>
                    }
                    <div className="searchModal">
                      <Search
                        placeholder="Search here"
                        className="searchInput"
                        onSearch={value => this.searchByJobTitle(value)}
                        onChange={ this.searchOnChange }
                        onFocus={this.searchFocus}
                        style={{top:0}}
                      />
                    </div>
                  </Col>
                  }
                  <div className="searchSuggestions">
                    <Col className="suggestion" xs={{ span: 24, offset: 0 }} lg={{ span: 14, offset: 0 }} style={{height: searchBy === 'preferred_job' || searchBy === 'education' ? '100%' : '40%', overflowY:'scroll', padding:20}}>
                      <div className="suggestionTitle">
                        {t('Suggestions')}
                      </div>
                      <div className="suggestionParent">
                        {suggestionShow && suggestionShow.map((suggestion, index) => {
                          if (suggestion && suggestion.hidden !== true ) {
                            return (
                              <p key={index} onClick={() => this.addTags(suggestion['value'])} style={{borderBottom:0, height:30}}>
                                {t(suggestion['value'])}
                              </p>
                            )
                          }
                        })}
                      </div>
                    </Col>
                    {searchBy !== 'preferred_job' && searchBy !== 'education' &&
                      <div>
                        <hr style={{display:'flex', marginLeft:15, marginRight:15, borderColor:'#a7a7a7'}}/>
                        <Col className="suggestion" xs={{ span: 24, offset: 0 }} lg={{ span: 10, offset: 0 }}  style={{height:'50%', overflowY:'scroll', paddingBottom:100, padding:20}}>
                        <div className="suggestionTitle spaceBetween">
                          <span>{t('Selected')} {t(searchBy)}</span>
                          <span onClick={() => this.clearTags()} className="clearBtn">{t('Clear all')}</span>
                        </div>
                          <div className="addedTagsParent">
                            {selectedTags && selectedTags.map((tag, index) => {
                              return (
                                <Tag className="tagDesignBottomMobile" key={tag} closable={true} onClose={() => this.handleCloseEdu(tag)}>
                                  <div className="tabDesigText">{t(tag)}</div>
                                </Tag>
                              )
                            })}
                          </div>
                        </Col>
                        <Button onClick={this.goToSearch} className="headerSearchButton"
                          style={{
                            position:'fixed',
                            display:'flex',
                            width:'100%',
                            borderRadius:0,
                            bottom:0,
                            height:45,
                            backgroundColor: '#4D77F3',
                            alignItems:'center',
                            justifyContent:'center',
                            color:'white'}}>{t('Search')}
                        </Button>
                      </div>
                    }
                  </div>
                </Col>
              }
            </Row>
          </AntHeader>
        </Col>
      </Row>
    );
  }
}
const mapState = state =>({
  auth: state.firebase.auth,
  pages: state.firestore.ordered.pages,
  header_message: state.firestore.ordered[random_messages],
  header_notification: state.firestore.data[state.firestore.data.company_profile ? matched_by_job : random_notification],
  company_data: state.firestore.data.company_profile ? state.firestore.data.company_profile : state.firestore.data.company_data,
  headerCandidateUsers: state.firestore.data.candidateUsers,
  header_job_posting: state.firestore.data.job_posting,
  matched_by_job_others: state.firestore.data.matched_by_job_others,
  header_matched_by_job: state.firestore.data[random_self_job],
  header_notification_all: state.firestore.data[notification_all],
  subscriptions: state.firestore.data.subscriptions,
})
const actions = {
  signout
};
const query = props => {
  const { company_id, auth } =  pathOr('', [], props);
  let cond_var = '1';
  let cond_val = '1';
  if(pathOr(false, [], company_id)){
    cond_var = 'company_id';
    cond_val = company_id;
  }else{
    cond_var = 'candidate_id';
    cond_val = pathOr('1', ['uid'], auth);
  }
  return [
    // {
    //   collection: 'users',
    //   doc: props.auth.uid || 'guest',
    //   subcollections: [
    //     {
    //       collection: 'pages',
    //     },
    //   ],
    //   storeAs: 'pages',
    // },
    {
      collection: 'messages',
      where: [
        [cond_var, '==', cond_val]
      ],
      storeAs: random_messages
    },
    {
      collection: 'matched_by_job',
      storeAs: random_notification
    },
    {
      collection: 'company',
      doc: company_id,
      storeAs: 'company_data'
    },
    {
      collection: 'matched_by_job',
      where: [
        ['liked', 'array-contains', cond_val]
      ],
      storeAs: 'matched_by_job_others'
    },
    {
      collection: 'notification',
      storeAs: notification_all
    },
    {
      collection: 'subscriptions'
    }
  ]
};
export default withRouter(connect(mapState, actions)(firestoreConnect(query)(Header)));
// export default compose(
//   firestoreConnect((props) => {
//     return [
//       {
//         collection: 'messages',
//         storeAs: 'header_message',
//       },
//     ]
//   }),
// 	connect((state, props) => {
// 		return {
//       auth: state.firebase.auth,
//       header_message: state.firestore.ordered.header_message,
// 		};
//   })
// )((withNamespaces())(withRouter(Header)));
