import React, { Component } from 'react'
// import { notification } from 'antd'
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux'
import { compose } from 'redux'
import qs from 'query-string';

class EmailVerifyText extends Component {
  state = {
    msg: null,
  }
  componentDidMount(){
    const { history, firebase } = this.props;
    const parseSearchPath = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    firebase.auth().applyActionCode(parseSearchPath['oobCode']).then(function(resp) {
      console.log(resp)
    }).catch(function(error) {
      console.error(error)
      // console.log(this)
    });
  }
  handleRedirect = () => {
    this.props.history.push('/')
  }
  render() {
    const { t } = this.props
        return (
          <div style={{textAlign:"center"}}>
            <img className="authPaperPlan" src="/images/auth/Paperplane.png" align="middle" alt="planLogo" style={{filter: 'invert(40%) sepia(69%) saturate(1236%) hue-rotate(205deg) brightness(97%) contrast(100%)'}} />
            <p className="authEmailVerifyText">
              {t('Your email has been verified. Please click')}
              {/* <b style={{color:'blue', cursor: 'pointer'}} onClick={this.handleRedirect}>{t('here')}</b> {t('to login')} */}
            </p>
          </div>
        )
    }
}

// export default EmailVerify
const enhancer = compose(
  firestoreConnect((props, state) => {
    return []
  }),
  connect((state, props) => {
    return {
      firebase: props && props.firebase,
    }
  }),
)

export default enhancer(EmailVerifyText)
