import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import { getFirebase } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { firestoreConnect } from 'react-redux-firebase';
import AdminHeader from '../header/adminHeader';
import AdminNav from '../nav/adminNav';
import './adminStyles.css';

const { Content, Footer } = Layout;

const AdminLayout = ({
  component: Component,
  ...rest
}) => {
  const firebase = getFirebase();
  const auth = firebase.auth();
  const authenticated = auth.currentUser;
  const { current_user, location } = rest;

  if (!authenticated) {
    return (<Redirect from='*' to='/' />);
  }
  if (current_user && current_user.role === 'admin' && !location.pathname.includes('/blogs') && ['/useroverview', '/users', '/companies', '/statistics'].indexOf(location.pathname) ==  -1) {
    return (<Redirect from='*' to='/' />);
  } else if (current_user && current_user.role !== 'admin') {
    return (<Redirect from='*' to='/' />);
  } else {
    return (
      <Route
        {...rest}
        render={matchProps => (
          <Layout style={{ minHeight: '100vh'}}>
            <Layout style={{ background:'#fff' }}>
              <AdminHeader />
              <AdminNav />
              <Content style={{paddingLeft: 250, marginTop: 50, paddingTop: 40}}>
                <div style={{ background: '#fff', minHeight: 360 }}>
                  <Component {...matchProps}/>
                </div>
              </Content>
            </Layout>
          </Layout>
        )}
      />
    )
  }
};

export default compose(
  firestoreConnect( (props, state) => {
    let uid = pathOr(null, ['firebase', 'auth', 'uid'], state.getState());
    if (uid) {
      return [
        {
          collection: 'users',
          doc: uid,
          storeAs: 'currentUser',
        },
      ];
    } else {
      return [];
    }
  }),
  connect( (state, props) => {
    const current_user = pathOr(null, ['firestore', 'data', 'currentUser'], state);
    return {
      current_user,
    };
  }),
)(AdminLayout);
