import React, { Component } from 'react'
import { Field, reduxForm, change } from 'redux-form'
import { render } from '../../../utils/form'
import { Form, Icon, Input, Button, Alert } from 'antd'
import { confirmation } from 'redux-form-validators'
import qs from 'query-string';

const InputField = render(Input)
const password = value => value && !/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/i.test(value) ? "Uw wachtwoord moet minimaal 8 tekens lang zijn en ten minste één hoofdletter en één cijfer bevatten." : undefined;
const required = value => (value ? undefined : "Dit is een verplicht veld.");

class ResetPassword extends Component {
  componentDidMount() {
    const { history } = this.props
    const parseSearch = qs.parse(history.location.search, { ignoreQueryPrefix: true })
    this.props.dispatch(change('resetPasswordForm', 'code', parseSearch['oobCode']));
  }

    render() {
        const { resetpassword, error, handleSubmit, submitting, t } = this.props

        return (
            <Form onSubmit={handleSubmit(resetpassword)}>
                {error && <Alert className="error" type="warning" message={error} showIcon />}

                <Field
                    name="code"
                    type="hidden"
                    component={InputField}
                    validate={required}
                    value='dsadsa'
                />

                <Field
                    name="password"
                    type="password"
                    validate={[password,required]}
                    component={InputField}
                    prefix={<Icon type="lock" />}
                    placeholder={t("Password")}
                />
                <Field
                    name="c_password"
                    type="password"
                    component={InputField}
                    prefix={<Icon type="lock" />}
                    placeholder={t("Confirm Password")}
                    validate={[confirmation({ field: 'password', msg: t("Passwords do not match") }),required]}
                />
                <p>
                  {t("password_msg_first")}<br/>{t("password_msg_second")}
                </p>
                <Button
                    className="form-button"
                    type="primary-global"
                    loading={submitting}
                    htmlType="submit"
                >
                    {t("reset_password_button")}
                </Button>
            </Form>
        )
    }
}

export default reduxForm({ form: 'resetPasswordForm' })(ResetPassword)
