import React from 'react';
import { Form, Radio } from 'antd';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

export const render = Component => ({ input, meta, children, hasFeedback, label, ...rest }) => {
  const hasError = meta.touched && meta.invalid;
  // const manualValidation = rest.manualValidate;
  if (rest.type === 'radioButton') {
    const options = rest.values && rest.values.split(',');
    return(
      <Form.Item
        label={label}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
        labelCol={rest.labelCol}
        wrapperCol={rest.wrapperCol}
        colon={false}
        className={ rest.c || (rest.required && 'required')}
      >
        <RadioGroup {...input} {...rest} children={children}>
          {options && options.map( option => (
            <RadioButton key={option} className="licenceRadio" value={option}>{option}</RadioButton>
          ))}
        </RadioGroup>
      </Form.Item>
    );
  }
  if (input.name === 'birthdate') {
    rest.placeholder = 'dd-mm-yyyy';
  }
  if (input.name === 'birthday') {
    delete input.onBlur; //This was causing crash in date component
  }
  if (input.name === 'email' && rest.email) {
    rest.disabled = true;
  }
  if (rest.type === "select") {
    return (
      <Form.Item
        label={label}
        validateStatus={hasError ? 'error' : 'success'}
        hasFeedback={hasFeedback && hasError}
        help={hasError && meta.error}
        labelCol={rest.labelCol}
        wrapperCol={rest.wrapperCol}
        colon={false}
        className={ rest.labelclass || (rest.required && 'required')}
      >
        <Component {...input} {...rest} children={children} value={input.value ? input.value : undefined} placeholder={rest.placeholder}/>
      </Form.Item>
    );
  }
  return (
    <Form.Item
      label={label}
      validateStatus={hasError ? 'error' : 'success'}
      hasFeedback={hasFeedback && hasError}
      help={hasError && meta.error}
      labelCol={rest.labelCol}
      wrapperCol={rest.wrapperCol}
      colon={false}
      className={ rest.labelclass || (rest.required && 'required')}
    >
      <Component {...input} {...rest} children={children} placeholder={rest.placeholder}/>
    </Form.Item>
  );
}
