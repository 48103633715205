import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import GlobalEN from "./translations/globalEN.json"
import GlobalNL from "./translations/globalNL.json"

const resources = {
	en: {
    translation: GlobalEN
  },
  nl: {
    translation: GlobalNL
  }
};

i18n
	.use(reactI18nextModule)
		.init({
			resources,
			lng: 'nl',
			keySeparator: false,
			interpolation: {
				escapeValue: false
			}
		});

export default i18n;
