import React, { Component } from 'react';
import { Field, reduxForm, reset, change } from 'redux-form';
import { render } from '../../../utils/form';
import { Form, Input, Button, Alert, Col, Row, Select, Checkbox } from 'antd';
import { confirmation } from 'redux-form-validators';
const InputField = render(Input);
const SelectField = render(Select);
const Option = Select.Option;

const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(value) ? 'Het emailadres is niet correct' : undefined;
const number = value => value && value.toString().length >= 16 ? 'Vul een correct telefoonnnumer in' : undefined;
const prefix = value => value && !IsInRange0100(value) ? 'Vul een correcte landcode in' : undefined;
const password = value => value && !/^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][\w~@#$%^&*+=`|{}:;!.?\"()\[\]-]{7,}$/i.test(value) ? 'wachtwoord voldoet niet aan de eisen' : undefined;
const required = value => (value ? undefined : 'verplicht veld');

function IsInRange0100(number)
{
  return number >= 0 && number <= 100 ;
}

class SignUpForm extends Component {
  state={
    signupCompany: false,
    termsAccepted: false,
    BtnSignUp: true
  };

  render() {
    const { signup, handleSubmit, submitting, dispatch, t, error } = this.props;
    const { signupCompany, termsAccepted, BtnSignUp } = this.state;
    dispatch(change('signUpForm', 'prefix', '+31'));

    return (
      <div>
          <div style={{textAlign: 'center'}}>
            <div className="howRegister">{t('How do you want to register?')}</div>
              <div style={{display: 'flex', justifyContent: 'space-around'}}>
                <Button onClick={() => {this.setState({signupCompany: false}); dispatch(reset('signUpForm'))}} type={(!signupCompany ? "primary-global" : 'default-global') + ' signup-role large'} >{t("signup_candidate")}</Button>
                <Button onClick={() => {this.setState({signupCompany: true}); dispatch(reset('signUpForm'))}} type={(signupCompany ? "primary-global" : 'default-global') + ' signup-role large'} >{t("signup_company")}</Button>
              </div>
          </div>
        {!signupCompany &&
          <Form style={{paddingTop:'10px'}} onSubmit={handleSubmit(signup)}>
            {error && <Alert className="error" type="warning" message={error} showIcon />}
            <Row>
              <Col className="authInlineLeft" xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>
                <Field
                  name="first_name"
                  type="text"
                  component={InputField}
                  validate={required}
                  placeholder={`${t("First Name")} *`}
                />
              </Col>
              <Col className="authInlineRight" xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>
                <Field
                  name="last_name"
                  type="text"
                  component={InputField}
                  validate={required}
                  placeholder={`${t("Last Name")} *`}
                />
              </Col>
            </Row>
            <Field
              name="email"
              type="email"
              validate={[email, required]}
              component={InputField}
              placeholder={`${t("Email")} *`}
            />
            <Row>
              <Col className="authInlineLeft" xs={{span:24}} sm={{span:24}} md={{span:7}} lg={{span:7}}>
                <Field
                  name="prefix"
                  // type="number"
                  validate={prefix}
                  component={InputField}
                  placeholder={t("Area Code")}
                />
              </Col>
              <Col className="authInlineRight" xs={{span:24}} sm={{span:24}} md={{span:17}} lg={{span:17}}>
                <Field
                  name="phone"
                  type="number"
                  validate={number}
                  component={InputField}
                  placeholder={t("Phone Number Optional")}
                />
              </Col>
            </Row>
            <Row>
              <Col className="authInlineLeft" xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>
                <Field
                  name="password"
                  type="password"
                  validate={password}
                  component={InputField}
                  placeholder={`${t("Password")} *`}
                />
              </Col>
              <Col className="authInlineRight" xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>
                <Field
                  name="c_password"
                  type="password"
                  component={InputField}
                  placeholder={`${t("Confirm Password")} *`}
                  validate={confirmation({ field: 'password', msg: "Password mismatched" })}
                />
              </Col>
            </Row>
            <span style={{display: 'block'}}>
              {t("password_msg_first")}<br/>{t("password_msg_second")}
            </span>
            <br />
            <Checkbox style={{textAlign: 'center', display: 'block'}} checked={termsAccepted} onChange={(e) => this.setState({termsAccepted: e.target.checked, BtnSignUp: !e.target.checked})}>{t("I agree to unlocQed's")} <a target="_blank" href="/statements/Gebruikersvoorwaarden_van_UnlocQed_voor_Werkzoekenden.pdf">{t("Terms and Conditions")}</a></Checkbox>
            <Button
              style={{marginTop:'25px'}}
              className="form-button"
              type="primary-global"
              htmlType="submit"
              loading={submitting}
              disabled={BtnSignUp}
            >
              {t("sign_up")}
            </Button>
          </Form>
        }
        {signupCompany &&
          <Form style={{paddingTop:'10px'}} onSubmit={handleSubmit(signup)}>
            {error && <Alert className="error" type="warning" message={error} showIcon />}
            <Field
              name="type"
              type="select"
              component={SelectField}
              validate={required}
              labelclass="register-dropdown"
              placeholder={`${t("Company Type")} *`}
            >
              <Option value="bedrijf">Bedrijf</Option>
              <Option value="tussenpersoon">Tussenpersoon</Option>
            </Field>
            <Field
              name="company_name"
              type="text"
              component={InputField}
              validate={required}
              placeholder={`${t("Company Name")} *`}
            />
            <Row>
              <Col className="authInlineLeft" xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>
                <Field
                  name="full_name"
                  type="text"
                  validate={required}
                  component={InputField}
                  placeholder={`${t("Your First Name")} *`}
                />
              </Col>
              <Col className="authInlineRight" xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>
                <Field
                  name="last_name"
                  type="text"
                  component={InputField}
                  validate={required}
                  placeholder={`${t("Your Last Name")} *`}
                />
              </Col>
            </Row>
            <Field
              name="role"
              type="text"
              component={InputField}
              validate={required}
              placeholder={`${t("Role")} *`}
            />
            <Field
              name="email"
              type="email"
              validate={[email, required]}
              component={InputField}
              placeholder={`${t("Email")} *`}
            />
            <Row>
              <Col className="authInlineLeft" xs={{span:24}} sm={{span:24}} md={{span:7}} lg={{span:7}}>
                <Field
                  name="prefix"
                  // type="number"
                  validate={prefix}
                  component={InputField}
                  placeholder={t("Area Code")}
                />
              </Col>
              <Col className="authInlineRight" xs={{span:24}} sm={{span:24}} md={{span:17}} lg={{span:17}}>
                <Field
                  name="phone"
                  type="number"
                  validate={number}
                  component={InputField}
                  placeholder={t("Phone Number Optional")}
                />
              </Col>
            </Row>
            <Row>
              <Col className="authInlineLeft" xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>
                <Field
                  name="password"
                  type="password"
                  validate={password}
                  component={InputField}
                  placeholder={`${t("Password")} *`}
                />
              </Col>
              <Col className="authInlineRight" xs={{span:24}} sm={{span:24}} md={{span:12}} lg={{span:12}}>
                <Field
                  name="c_password"
                  type="password"
                  component={InputField}
                  placeholder={`${t("Confirm Password")} *`}
                  validate={confirmation({ field: 'password', msg: "Password is not matched" })}
                />
              </Col>
            </Row>
            <span style={{color:'#a7a7a7', display: 'block'}} >
              {t("password_msg_first")}<br/>{t("password_msg_second")}
            </span>
            <br />
            <Checkbox style={{textAlign: 'center', display: 'block'}} checked={termsAccepted} onChange={(e) => this.setState({termsAccepted: e.target.checked, BtnSignUp: !e.target.checked})}>{t("I agree to unlocQed's")} <a target="_blank" href="/statements/Gebruikersvoorwaarden_van_UnlocQed_voor_Werkgever.pdf">{t("Terms and Conditions")}</a></Checkbox>
            <Button
              style={{marginTop:'25px'}}
              htmlType="submit"
              className="form-button"
              type="primary-global"
              loading={submitting}
              disabled={BtnSignUp}
            >
              {t("sign_up")}
            </Button>
          </Form>
        }
      </div>
    )
  }
}

export default reduxForm({ form: 'signUpForm' })(SignUpForm)
