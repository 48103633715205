import React from 'react';
import ReactDOM from 'react-dom';
import {domainUrl} from "../../../firebase";


class MyWindowPortal extends React.PureComponent {
  constructor(props) {
    super(props);
    // STEP 1: create a container <div>
    this.containerEl = document.createElement('div');
    this.externalWindow = null;
  }

  render() {
    // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
    return ReactDOM.createPortal(this.props.children, this.containerEl);
  }

  componentDidMount() {
    const {id, userId} = this.props
    // STEP 3: open a new browser window and store a reference to it
    this.externalWindow = window.open(`${domainUrl}/video-call/${id}/${userId}`, '', 'width=800,height=450,left=200,top=200');
    // this.externalWindow = window.open(`http://localhost:3000/video-call/${id}/${userId}`, '', 'width=800,height=450,left=200,top=200');
    this.externalWindow.document.title = 'unlocQed.nl';

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);
    this.externalWindow.addEventListener('beforeunload', () => {
      this.props.closeRoom();
    });

  }

  componentWillUnmount() {
    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window
    this.externalWindow.close();
  }
}
export default MyWindowPortal;
