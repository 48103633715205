import { SubmissionError } from 'redux-form'
import history from '../../utils/history'
import { SystemError } from '../../utils/error'
import * as ReactGA from 'react-ga';
import axios from 'axios';
import {fbFunctionsURL} from "../../firebase";
import moment from 'moment';
import * as firebaseui from 'firebaseui'
import { pathOr } from "ramda";

export const login = (creds) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      localStorage.clear();
      const signInUser = await getFirebase().auth().signInWithEmailAndPassword(creds.email, creds.password);
      await getFirebase().auth().setPersistence("local")
      const current_user = await getFirestore().collection('users').doc(signInUser.user.uid).get()
        .then((doc) => {
          if (doc.exists) {
            return doc.data();
          } else {
            console.log('No such document!');
          }
        }).catch((error) => {
          console.log('Error getting document: ', error);
        });

      ReactGA.set({ userId: current_user && current_user.email });
      ReactGA.event({
        category: 'Auth',
        action: 'SignIn',
        label: 'success',
        nonInteraction: false,
      });

      if (current_user.role === 'company') {
        history.push(`/companyprofile/${current_user.company_id}`);
      }
      if (current_user.role === 'candidate') {
        history.push('/dashboard');
      }
      if (current_user.role === 'admin') {
        history.push('/users');
      }
      // history.goBack();
    } catch (error) {
      ReactGA.event({
        category: 'Auth',
        action: 'SignIn',
        label: 'failed',
        nonInteraction: false
      });
      if (error.code === 'auth/argument-error') throw new SubmissionError({ _error: 'Missing field... did you type in everything?' });
      else throw new SubmissionError({
        _error: error.code === 'auth/user-not-found' ? 'Er bestaat geen account, dat bij dit e-mailadres hoort. De gebruiker is mogelijk verwijderd.' : 'Het wachtwoord is incorrect of de gebruiker bestaat niet'
      });
    }
  };
};

export const signup = (creds) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      localStorage.clear();
      // Create the user in Firebase
      const userForFirebase = await getFirebase().auth().createUserWithEmailAndPassword(creds.email, creds.password);
      // history.push('/termsandconditions/')
      // await getFirebase().auth().setCustomUserClaims(userForFirebase.user.uid, {
      //   candidate: true
      // });
      getFirebase().auth().languageCode = 'nl';
      await getFirebase().auth().currentUser.sendEmailVerification();
      let userForFirestore = {};
      // Create the user in Firestore
      if (creds.company_name) {
        const randomID = await getFirestore().collection("company").doc();
        userForFirestore = {
          company_id: creds.inviteid ? creds.company_id : randomID.id,
          company_name: creds.company_name,
          full_name: creds.full_name,
          last_name: creds.last_name,
          role: 'company',
          email: creds.email,
          phone: pathOr('', ['phone'], creds),
          prefix: pathOr('', ['prefix'], creds),
          company_role: creds.role,
          terms_and_conditions: true,
          createdAt: Date.now(), //getFirestore().FieldValue.serverTimestamp(),
        };

        await getFirestore().collection('users').doc(userForFirebase.user.uid).set(userForFirestore)
        .then(async () => {
          await getFirestore().collection('company').doc(creds.inviteid ? creds.company_id : randomID.id).set({
            companyname: creds.company_name,
            phone: pathOr('', ['phone'], creds),
            contact_prefix: pathOr('', ['prefix'], creds),
            type: creds.type,
            //TRIAL SUBSCRIPTION UPON SIGNUP
            subscriptionId: 'exG45vjEqW8zL4I5l2zw',
            subscriptionValid: moment().add('1', 'month').valueOf(),
            subscribeUser: userForFirebase.user.uid,
            jobs: 1,
            likes: 5,
            amountPayed: 0
          }, {merge: true});

          const factuursturenObj = {
            contact: creds.full_name,
            company: creds.company_name,
            phone: pathOr('', ['phone'], creds),
            email: creds.email,
            mailintro: `Dear ${creds.full_name},`,
            companyId: creds.inviteid ? creds.company_id : randomID.id,
          };
          try {
            const factuursturenRes = await axios.post(`${fbFunctionsURL}/addCompanyToFactuursturen`, factuursturenObj);
            // console.log('factuursturenRes: ', factuursturenRes);
          } catch (e) {
            console.log('factuursturen error: ', e);
          }
          if (creds.inviteid) {
            await getFirestore().collection('invites').doc(creds.inviteid).delete();
            history.push('/emailverify/');
          }else{
            history.push('/emailverify/');
          }
        })
      } else {
        userForFirestore = {
          first_name: creds.first_name,
          last_name: creds.last_name,
          role: 'candidate',
          email: creds.email,
          phone: pathOr('', ['phone'], creds),
          prefix: pathOr('', ['prefix'], creds),
          terms_and_conditions: true,
          createdAt: Date.now(), //getFirestore().FieldValue.serverTimestamp(),
        };

        ReactGA.event({
          category: 'Auth',
          action: 'SignUp',
          label: 'success',
          nonInteraction: false,
        });

        await getFirestore().collection('users').doc(userForFirebase.user.uid).set(userForFirestore)
        .then(async () => {
          if (creds.inviteid) {
            await getFirestore().collection('invites').doc(creds.inviteid).delete();
            history.push('/emailverify/');
          }else{
            history.push('/emailverify/');
          }
        })
      }
    } catch (error) {
      if (error.code === 'auth/argument-error') throw new SubmissionError({ _error: 'Missing field... did you type in everything?' });
      else throw new SubmissionError({ _error: error.code === 'auth/email-already-in-use' ? 'Het e-mailadres is al reeds in gebruik door een ander account' : error.message });
    }
  };
};

export const forgetpassword = (creds) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      getFirebase().auth().languageCode = 'nl';
      await getFirebase().auth().sendPasswordResetEmail(creds.email);
      ReactGA.event({
        category: 'Auth',
        action: 'forget Password',
        label: 'success',
        nonInteraction: false,
      });
    } catch (error) {
      ReactGA.event({
        category: 'Auth',
        action: 'forget Password',
        label: 'failed',
        nonInteraction: false,
      });
      if (error.code === 'auth/argument-error') throw new SubmissionError({ _error: 'Missing field... did you type in everything?' });
      else throw new SubmissionError({ _error: error.message });
    }
  };
};

export const resetpassword = (creds) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      await getFirebase().auth().confirmPasswordReset(creds.code, creds.password)
      ReactGA.event({
        category: 'Auth',
        action: 'reset Password',
        label: 'success',
        nonInteraction: false,
      });
      history.push('/login');
    } catch (error) {
      ReactGA.event({
        category: 'Auth',
        action: 'reset Password',
        label: 'failed',
        nonInteraction: false,
      });
      if (error.code === 'auth/argument-error') throw new SubmissionError({ _error: 'Missing field... did you type in everything?' });
      else throw new SubmissionError({ _error: error.message });
    }
  };
};

export const signout = () => {
  return async (dispatch, getState, { getFirebase }) => {
    try {
      await getFirebase().auth().signOut();
      ReactGA.event({
        category: 'Auth',
        action: 'signOut',
        label: 'success',
        nonInteraction: false,
      });
      // history.push('/')
      window.location.href = '/';
    } catch (error) {
      ReactGA.event({
        category: 'Auth',
        action: 'signOut',
        label: 'failed',
        nonInteraction: false,
      });
      SystemError(error);
    }
  };
};
